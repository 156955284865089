/* eslint-disable indent */
import { $themeBreakpoints } from '@themeConfig'
import Resource from '@/api/resource'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    baseServerUrl: process.env.VUE_APP_MIX_BASE_SERVER_URL,
    params: {
      countries: [
        {
          id: 1,
          country_code: 'AF',
          country_name: 'Afghanistan',
          states: [],
        },
        {
          id: 2,
          country_code: 'AL',
          country_name: 'Albania',
          states: [],
        },
        {
          id: 3,
          country_code: 'DZ',
          country_name: 'Algeria',
          states: [],
        },
        {
          id: 4,
          country_code: 'AS',
          country_name: 'American Samoa',
          states: [],
        },
          {
              id: 5,
              country_code: 'AD',
              country_name: 'Andorra',
              states: [],
          },
          {
              id: 6,
              country_code: 'AO',
              country_name: 'Angola',
              states: [],
          },
          {
              id: 7,
              country_code: 'AI',
              country_name: 'Anguilla',
              states: [],
          },
          {
              id: 8,
              country_code: 'AQ',
              country_name: 'Antarctica',
              states: [],
          },
          {
              id: 9,
              country_code: 'AG',
              country_name: 'Antigua And Barbuda',
              states: [],
          },
          {
              id: 10,
              country_code: 'AR',
              country_name: 'Argentina',
              states: [],
          },
          {
          id: 11,
              country_code: 'AM',
              country_name: 'Armenia',
              states: [],
          },
          {
              id: 12,
              country_code: 'AW',
              country_name: 'Aruba',
              states: [],
          },
          {
              id: 13,
              country_code: 'AU',
              country_name: 'Australia',
              states: [],
          },
          {
              id: 14,
              country_code: 'AT',
              country_name: 'Austria',
              states: [],
          },
          {
              id: 15,
              country_code: 'AZ',
              country_name: 'Azerbaijan',
              states: [],
          },
          {
              id: 16,
              country_code: 'BS',
              country_name: 'Bahamas The',
              states: [],
          },
          {
              id: 17,
              country_code: 'BH',
              country_name: 'Bahrain',
              states: [],
          },
          {
              id: 18,
              country_code: 'BD',
              country_name: 'Bangladesh',
              states: [],
          },
          {
              id: 19,
              country_code: 'BB',
              country_name: 'Barbados',
              states: [],
          },
          {
              id: 20,
              country_code: 'BY',
              country_name: 'Belarus',
              states: [],
          },
          {
              id: 21,
              country_code: 'BE',
              country_name: 'Belgium',
              states: [],
          },
          {
              id: 22,
              country_code: 'BZ',
              country_name: 'Belize',
              states: [],
          },
          {
              id: 23,
              country_code: 'BJ',
              country_name: 'Benin',
              states: [],
          },
          {
              id: 24,
              country_code: 'BM',
              country_name: 'Bermuda',
              states: [],
          },
          {
              id: 25,
              country_code: 'BT',
              country_name: 'Bhutan',
              states: [],
          },
          {
              id: 26,
              country_code: 'BO',
              country_name: 'Bolivia',
              states: [],
          },
          {
              id: 27,
              country_code: 'BA',
              country_name: 'Bosnia and Herzegovina',
              states: [],
          },
          {
              id: 28,
              country_code: 'BW',
              country_name: 'Botswana',
              states: [],
          },
          {
              id: 29,
              country_code: 'BV',
              country_name: 'Bouvet Island',
              states: [],
          },
          {
              id: 30,
              country_code: 'BR',
              country_name: 'Brazil',
              states: [],
          },
          {
              id: 31,
              country_code: 'IO',
              country_name: 'British Indian Ocean Territory',
              states: [],
          },
          {
              id: 32,
              country_code: 'BN',
              country_name: 'Brunei',
              states: [],
          },
          {
              id: 33,
              country_code: 'BG',
              country_name: 'Bulgaria',
              states: [],
          },
          {
              id: 34,
              country_code: 'BF',
              country_name: 'Burkina Faso',
              states: [],
          },
          {
              id: 35,
              country_code: 'BI',
              country_name: 'Burundi',
              states: [],
          },
          {
              id: 36,
              country_code: 'KH',
              country_name: 'Cambodia',
              states: [],
          },
          {
              id: 37,
              country_code: 'CM',
              country_name: 'Cameroon',
              states: [],
          },
          {
              id: 38,
              country_code: 'CA',
              country_name: 'Canada',
              states: [],
          },
          {
              id: 39,
              country_code: 'CV',
              country_name: 'Cape Verde',
              states: [],
          },
          {
              id: 40,
              country_code: 'KY',
              country_name: 'Cayman Islands',
              states: [],
          },
          {
              id: 41,
              country_code: 'CF',
              country_name: 'Central African Republic',
              states: [],
          },
          {
              id: 42,
              country_code: 'TD',
              country_name: 'Chad',
              states: [],
          },
          {
              id: 43,
              country_code: 'CL',
              country_name: 'Chile',
              states: [],
          },
          {
              id: 44,
              country_code: 'CN',
              country_name: 'China',
              states: [],
          },
          {
              id: 45,
              country_code: 'CX',
              country_name: 'Christmas Island',
              states: [],
          },
          {
              id: 46,
              country_code: 'CC',
              country_name: 'Cocos (Keeling) Islands',
              states: [],
          },
          {
              id: 47,
              country_code: 'CO',
              country_name: 'Colombia',
              states: [],
          },
          {
              id: 48,
              country_code: 'KM',
              country_name: 'Comoros',
              states: [],
          },
          {
              id: 49,
              country_code: 'CG',
              country_name: 'Congo',
              states: [],
          },
          {
              id: 50,
              country_code: 'CD',
              country_name: 'Congo The Democratic Republic Of The',
              states: [],
          },
          {
              id: 51,
              country_code: 'CK',
              country_name: 'Cook Islands',
              states: [],
          },
          {
              id: 52,
              country_code: 'CR',
              country_name: 'Costa Rica',
              states: [],
          },
          {
              id: 53,
              country_code: 'CI',
              country_name: 'Cote D\'Ivoire (Ivory Coast)',
              states: [],
          },
          {
              id: 54,
              country_code: 'HR',
              country_name: 'Croatia (Hrvatska)',
              states: [],
          },
          {
              id: 55,
              country_code: 'CU',
              country_name: 'Cuba',
              states: [],
          },
          {
              id: 56,
              country_code: 'CY',
              country_name: 'Cyprus',
              states: [],
          },
          {
              id: 57,
              country_code: 'CZ',
              country_name: 'Czech Republic',
              states: [],
          },
          {
              id: 58,
              country_code: 'DK',
              country_name: 'Denmark',
              states: [],
          },
          {
              id: 59,
              country_code: 'DJ',
              country_name: 'Djibouti',
              states: [],
          },
          {
              id: 60,
              country_code: 'DM',
              country_name: 'Dominica',
              states: [],
          },
          {
              id: 61,
              country_code: 'DO',
              country_name: 'Dominican Republic',
              states: [],
          },
          {
              id: 62,
              country_code: 'TP',
              country_name: 'East Timor',
              states: [],
          },
          {
              id: 63,
              country_code: 'EC',
              country_name: 'Ecuador',
              states: [],
          },
          {
              id: 64,
              country_code: 'EG',
              country_name: 'Egypt',
              states: [],
          },
          {
              id: 65,
              country_code: 'SV',
              country_name: 'El Salvador',
              states: [],
          },
          {
              id: 66,
              country_code: 'GQ',
              country_name: 'Equatorial Guinea',
              states: [],
          },
          {
              id: 67,
              country_code: 'ER',
              country_name: 'Eritrea',
              states: [],
          },
          {
              id: 68,
              country_code: 'EE',
              country_name: 'Estonia',
              states: [],
          },
          {
              id: 69,
              country_code: 'ET',
              country_name: 'Ethiopia',
              states: [],
          },
          {
              id: 70,
              country_code: 'XA',
              country_name: 'External Territories of Australia',
              states: [],
          },
          {
              id: 71,
              country_code: 'FK',
              country_name: 'Falkland Islands',
              states: [],
          },
          {
              id: 72,
              country_code: 'FO',
              country_name: 'Faroe Islands',
              states: [],
          },
          {
              id: 73,
              country_code: 'FJ',
              country_name: 'Fiji Islands',
              states: [],
          },
          {
              id: 74,
              country_code: 'FI',
              country_name: 'Finland',
              states: [],
          },
          {
              id: 75,
              country_code: 'FR',
              country_name: 'France',
              states: [],
          },
          {
              id: 76,
              country_code: 'GF',
              country_name: 'French Guiana',
              states: [],
          },
          {
              id: 77,
              country_code: 'PF',
              country_name: 'French Polynesia',
              states: [],
          },
          {
              id: 78,
              country_code: 'TF',
              country_name: 'French Southern Territories',
              states: [],
          },
          {
              id: 79,
              country_code: 'GA',
              country_name: 'Gabon',
              states: [],
          },
          {
              id: 80,
              country_code: 'GM',
              country_name: 'Gambia The',
              states: [],
          },
          {
              id: 81,
              country_code: 'GE',
              country_name: 'Georgia',
              states: [],
          },
          {
              id: 82,
              country_code: 'DE',
              country_name: 'Germany',
              states: [],
          },
          {
              id: 83,
              country_code: 'GH',
              country_name: 'Ghana',
              states: [],
          },
          {
              id: 84,
              country_code: 'GI',
              country_name: 'Gibraltar',
              states: [],
          },
          {
              id: 85,
              country_code: 'GR',
              country_name: 'Greece',
              states: [],
          },
          {
              id: 86,
              country_code: 'GL',
              country_name: 'Greenland',
              states: [],
          },
          {
              id: 87,
              country_code: 'GD',
              country_name: 'Grenada',
              states: [],
          },
          {
              id: 88,
              country_code: 'GP',
              country_name: 'Guadeloupe',
              states: [],
          },
          {
              id: 89,
              country_code: 'GU',
              country_name: 'Guam',
              states: [],
          },
          {
              id: 90,
              country_code: 'GT',
              country_name: 'Guatemala',
              states: [],
          },
          {
              id: 91,
              country_code: 'XU',
              country_name: 'Guernsey and Alderney',
              states: [],
          },
          {
              id: 92,
              country_code: 'GN',
              country_name: 'Guinea',
              states: [],
          },
          {
              id: 93,
              country_code: 'GW',
              country_name: 'Guinea-Bissau',
              states: [],
          },
          {
              id: 94,
              country_code: 'GY',
              country_name: 'Guyana',
              states: [],
          },
          {
              id: 95,
              country_code: 'HT',
              country_name: 'Haiti',
              states: [],
          },
          {
              id: 96,
              country_code: 'HM',
              country_name: 'Heard and McDonald Islands',
              states: [],
          },
          {
              id: 97,
              country_code: 'HN',
              country_name: 'Honduras',
              states: [],
          },
          {
              id: 98,
              country_code: 'HK',
              country_name: 'Hong Kong S.A.R.',
              states: [],
          },
          {
              id: 99,
              country_code: 'HU',
              country_name: 'Hungary',
              states: [],
          },
          {
              id: 100,
              country_code: 'IS',
              country_name: 'Iceland',
              states: [],
          },
          {
              id: 101,
              country_code: 'IN',
              country_name: 'India',
              states: [],
          },
          {
              id: 102,
              country_code: 'ID',
              country_name: 'Indonesia',
              states: [],
          },
          {
              id: 103,
              country_code: 'IR',
              country_name: 'Iran',
              states: [],
          },
          {
              id: 104,
              country_code: 'IQ',
              country_name: 'Iraq',
              states: [],
          },
          {
              id: 105,
              country_code: 'IE',
              country_name: 'Ireland',
              states: [],
          },
          {
              id: 106,
              country_code: 'IL',
              country_name: 'Israel',
              states: [],
          },
          {
              id: 107,
              country_code: 'IT',
              country_name: 'Italy',
              states: [],
          },
          {
              id: 108,
              country_code: 'JM',
              country_name: 'Jamaica',
              states: [],
          },
          {
              id: 109,
              country_code: 'JP',
              country_name: 'Japan',
              states: [],
          },
          {
              id: 110,
              country_code: 'XJ',
              country_name: 'Jersey',
              states: [],
          },
          {
              id: 111,
              country_code: 'JO',
              country_name: 'Jordan',
              states: [],
          },
          {
              id: 112,
              country_code: 'KZ',
              country_name: 'Kazakhstan',
              states: [],
          },
          {
              id: 113,
              country_code: 'KE',
              country_name: 'Kenya',
              states: [],
          },
          {
              id: 114,
              country_code: 'KI',
              country_name: 'Kiribati',
              states: [],
          },
          {
              id: 115,
              country_code: 'KP',
              country_name: 'Korea North',
              states: [],
          },
          {
              id: 116,
              country_code: 'KR',
              country_name: 'Korea South',
              states: [],
          },
          {
              id: 117,
              country_code: 'KW',
              country_name: 'Kuwait',
              states: [],
          },
          {
              id: 118,
              country_code: 'KG',
              country_name: 'Kyrgyzstan',
              states: [],
          },
          {
              id: 119,
              country_code: 'LA',
              country_name: 'Laos',
              states: [],
          },
          {
              id: 120,
              country_code: 'LV',
              country_name: 'Latvia',
              states: [],
          },
          {
              id: 121,
              country_code: 'LB',
              country_name: 'Lebanon',
              states: [],
          },
          {
              id: 122,
              country_code: 'LS',
              country_name: 'Lesotho',
              states: [],
          },
          {
              id: 123,
              country_code: 'LR',
              country_name: 'Liberia',
              states: [],
          },
          {
              id: 124,
              country_code: 'LY',
              country_name: 'Libya',
              states: [],
          },
          {
              id: 125,
              country_code: 'LI',
              country_name: 'Liechtenstein',
              states: [],
          },
          {
              id: 126,
              country_code: 'LT',
              country_name: 'Lithuania',
              states: [],
          },
          {
              id: 127,
              country_code: 'LU',
              country_name: 'Luxembourg',
              states: [],
          },
          {
              id: 128,
              country_code: 'MO',
              country_name: 'Macau S.A.R.',
              states: [],
          },
          {
              id: 129,
              country_code: 'MK',
              country_name: 'Macedonia',
              states: [],
          },
          {
              id: 130,
              country_code: 'MG',
              country_name: 'Madagascar',
              states: [],
          },
          {
              id: 131,
              country_code: 'MW',
              country_name: 'Malawi',
              states: [],
          },
          {
              id: 132,
              country_code: 'MY',
              country_name: 'Malaysia',
              states: [],
          },
          {
              id: 133,
              country_code: 'MV',
              country_name: 'Maldives',
              states: [],
          },
          {
              id: 134,
              country_code: 'ML',
              country_name: 'Mali',
              states: [],
          },
          {
              id: 135,
              country_code: 'MT',
              country_name: 'Malta',
              states: [],
          },
          {
              id: 136,
              country_code: 'XM',
              country_name: 'Man (Isle of)',
              states: [],
          },
          {
              id: 137,
              country_code: 'MH',
              country_name: 'Marshall Islands',
              states: [],
          },
          {
              id: 138,
              country_code: 'MQ',
              country_name: 'Martinique',
              states: [],
          },
          {
              id: 139,
              country_code: 'MR',
              country_name: 'Mauritania',
              states: [],
          },
          {
              id: 140,
              country_code: 'MU',
              country_name: 'Mauritius',
              states: [],
          },
          {
              id: 141,
              country_code: 'YT',
              country_name: 'Mayotte',
              states: [],
          },
          {
              id: 142,
              country_code: 'MX',
              country_name: 'Mexico',
              states: [],
          },
          {
              id: 143,
              country_code: 'FM',
              country_name: 'Micronesia',
              states: [],
          },
          {
              id: 144,
              country_code: 'MD',
              country_name: 'Moldova',
              states: [],
          },
          {
              id: 145,
              country_code: 'MC',
              country_name: 'Monaco',
              states: [],
          },
          {
              id: 146,
              country_code: 'MN',
              country_name: 'Mongolia',
              states: [],
          },
          {
              id: 147,
              country_code: 'MS',
              country_name: 'Montserrat',
              states: [],
          },
          {
              id: 148,
              country_code: 'MA',
              country_name: 'Morocco',
              states: [],
          },
          {
              id: 149,
              country_code: 'MZ',
              country_name: 'Mozambique',
              states: [],
          },
          {
              id: 150,
              country_code: 'MM',
              country_name: 'Myanmar',
              states: [],
          },
          {
              id: 151,
              country_code: 'NA',
              country_name: 'Namibia',
              states: [],
          },
          {
              id: 152,
              country_code: 'NR',
              country_name: 'Nauru',
              states: [],
          },
          {
              id: 153,
              country_code: 'NP',
              country_name: 'Nepal',
              states: [],
          },
          {
              id: 154,
              country_code: 'AN',
              country_name: 'Netherlands Antilles',
              states: [],
          },
          {
              id: 155,
              country_code: 'NL',
              country_name: 'Netherlands The',
              states: [],
          },
          {
              id: 156,
              country_code: 'NC',
              country_name: 'New Caledonia',
              states: [],
          },
          {
              id: 157,
              country_code: 'NZ',
              country_name: 'New Zealand',
              states: [],
          },
          {
              id: 158,
              country_code: 'NI',
              country_name: 'Nicaragua',
              states: [],
          },
          {
              id: 159,
              country_code: 'NE',
              country_name: 'Niger',
              states: [],
          },
          {
              id: 160,
              country_code: 'NG',
              country_name: 'Nigeria',
              states: [
                  {
                      id: 1,
                      name: 'Abia',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 1,
                              state_id: 1,
                              country_id: 160,
                              name: 'Aba North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 2,
                              state_id: 1,
                              country_id: 160,
                              name: 'Aba South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 3,
                              state_id: 1,
                              country_id: 160,
                              name: 'Arochukwu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 4,
                              state_id: 1,
                              country_id: 160,
                              name: 'Bende',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 5,
                              state_id: 1,
                              country_id: 160,
                              name: 'Ikwuano',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 6,
                              state_id: 1,
                              country_id: 160,
                              name: 'Isiala Ngwa North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 7,
                              state_id: 1,
                              country_id: 160,
                              name: 'Isiala Ngwa South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 8,
                              state_id: 1,
                              country_id: 160,
                              name: 'Isuikwuato',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 9,
                              state_id: 1,
                              country_id: 160,
                              name: 'Obi Ngwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 10,
                              state_id: 1,
                              country_id: 160,
                              name: 'Ohafia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 11,
                              state_id: 1,
                              country_id: 160,
                              name: 'Osisioma',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 12,
                              state_id: 1,
                              country_id: 160,
                              name: 'Ugwunagbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 13,
                              state_id: 1,
                              country_id: 160,
                              name: 'Ukwa East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 14,
                              state_id: 1,
                              country_id: 160,
                              name: 'Ukwa West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 15,
                              state_id: 1,
                              country_id: 160,
                              name: 'Umu Nneochi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 16,
                              state_id: 1,
                              country_id: 160,
                              name: 'Umuahia North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 17,
                              state_id: 1,
                              country_id: 160,
                              name: 'Umuahia South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 2,
                      name: 'Adamawa',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 18,
                              state_id: 2,
                              country_id: 160,
                              name: 'Demsa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 19,
                              state_id: 2,
                              country_id: 160,
                              name: 'Fufure',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 20,
                              state_id: 2,
                              country_id: 160,
                              name: 'Ganye',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 21,
                              state_id: 2,
                              country_id: 160,
                              name: 'Gayuk',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 22,
                              state_id: 2,
                              country_id: 160,
                              name: 'Gombi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 23,
                              state_id: 2,
                              country_id: 160,
                              name: 'Grie',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 24,
                              state_id: 2,
                              country_id: 160,
                              name: 'Hong',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 25,
                              state_id: 2,
                              country_id: 160,
                              name: 'Jada',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 26,
                              state_id: 2,
                              country_id: 160,
                              name: 'Lamurde',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 27,
                              state_id: 2,
                              country_id: 160,
                              name: 'Madagali',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 28,
                              state_id: 2,
                              country_id: 160,
                              name: 'Maiha',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 29,
                              state_id: 2,
                              country_id: 160,
                              name: 'Mayo Belwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 30,
                              state_id: 2,
                              country_id: 160,
                              name: 'Michika',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 31,
                              state_id: 2,
                              country_id: 160,
                              name: 'Mubi North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 32,
                              state_id: 2,
                              country_id: 160,
                              name: 'Mubi South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 33,
                              state_id: 2,
                              country_id: 160,
                              name: 'Numan',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 34,
                              state_id: 2,
                              country_id: 160,
                              name: 'Shelleng',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 35,
                              state_id: 2,
                              country_id: 160,
                              name: 'Song',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 36,
                              state_id: 2,
                              country_id: 160,
                              name: 'Toungo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 37,
                              state_id: 2,
                              country_id: 160,
                              name: 'Yola North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 38,
                              state_id: 2,
                              country_id: 160,
                              name: 'Yola South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 3,
                      name: 'Akwa Ibom',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 39,
                              state_id: 3,
                              country_id: 160,
                              name: 'Abak',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 40,
                              state_id: 3,
                              country_id: 160,
                              name: 'Eastern Obolo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 41,
                              state_id: 3,
                              country_id: 160,
                              name: 'Eket',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 42,
                              state_id: 3,
                              country_id: 160,
                              name: 'Esit Eket',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 43,
                              state_id: 3,
                              country_id: 160,
                              name: 'Essien Udim',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 44,
                              state_id: 3,
                              country_id: 160,
                              name: 'Etim Ekpo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 45,
                              state_id: 3,
                              country_id: 160,
                              name: 'Etinan',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 46,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ibeno',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 47,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ibesikpo Asutan',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 48,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ibiono-Ibom',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 49,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ika',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 50,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ikono',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 51,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ikot Abasi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 52,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ikot Ekpene',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 53,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ini',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 54,
                              state_id: 3,
                              country_id: 160,
                              name: 'Itu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 55,
                              state_id: 3,
                              country_id: 160,
                              name: 'Mbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 56,
                              state_id: 3,
                              country_id: 160,
                              name: 'Mkpat-Enin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 57,
                              state_id: 3,
                              country_id: 160,
                              name: 'Nsit-Atai',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 58,
                              state_id: 3,
                              country_id: 160,
                              name: 'Nsit-Ibom',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 59,
                              state_id: 3,
                              country_id: 160,
                              name: 'Nsit-Ubium',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 60,
                              state_id: 3,
                              country_id: 160,
                              name: 'Obot Akara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 61,
                              state_id: 3,
                              country_id: 160,
                              name: 'Okobo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 62,
                              state_id: 3,
                              country_id: 160,
                              name: 'Onna',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 63,
                              state_id: 3,
                              country_id: 160,
                              name: 'Oron',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 64,
                              state_id: 3,
                              country_id: 160,
                              name: 'Oruk Anam',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 65,
                              state_id: 3,
                              country_id: 160,
                              name: 'Udung-Uko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 66,
                              state_id: 3,
                              country_id: 160,
                              name: 'Ukanafun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 67,
                              state_id: 3,
                              country_id: 160,
                              name: 'Uruan',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 68,
                              state_id: 3,
                              country_id: 160,
                              name: 'Urue-Offong/Oruko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 69,
                              state_id: 3,
                              country_id: 160,
                              name: 'Uyo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 4,
                      name: 'Anambra',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 70,
                              state_id: 4,
                              country_id: 160,
                              name: 'Aguata',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 71,
                              state_id: 4,
                              country_id: 160,
                              name: 'Anambra East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 72,
                              state_id: 4,
                              country_id: 160,
                              name: 'Anambra West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 73,
                              state_id: 4,
                              country_id: 160,
                              name: 'Anaocha',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 74,
                              state_id: 4,
                              country_id: 160,
                              name: 'Awka North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 75,
                              state_id: 4,
                              country_id: 160,
                              name: 'Awka South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 76,
                              state_id: 4,
                              country_id: 160,
                              name: 'Ayamelum',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 77,
                              state_id: 4,
                              country_id: 160,
                              name: 'Dunukofia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 78,
                              state_id: 4,
                              country_id: 160,
                              name: 'Ekwusigo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 79,
                              state_id: 4,
                              country_id: 160,
                              name: 'Idemili North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 80,
                              state_id: 4,
                              country_id: 160,
                              name: 'Idemili South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 81,
                              state_id: 4,
                              country_id: 160,
                              name: 'Ihiala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 82,
                              state_id: 4,
                              country_id: 160,
                              name: 'Njikoka',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 83,
                              state_id: 4,
                              country_id: 160,
                              name: 'Nnewi North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 84,
                              state_id: 4,
                              country_id: 160,
                              name: 'Nnewi South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 85,
                              state_id: 4,
                              country_id: 160,
                              name: 'Ogbaru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 86,
                              state_id: 4,
                              country_id: 160,
                              name: 'Onitsha North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 87,
                              state_id: 4,
                              country_id: 160,
                              name: 'Onitsha South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 88,
                              state_id: 4,
                              country_id: 160,
                              name: 'Orumba North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 89,
                              state_id: 4,
                              country_id: 160,
                              name: 'Orumba South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 90,
                              state_id: 4,
                              country_id: 160,
                              name: 'Oyi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 5,
                      name: 'Bauchi',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 91,
                              state_id: 5,
                              country_id: 160,
                              name: 'Alkaleri',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 92,
                              state_id: 5,
                              country_id: 160,
                              name: 'Bauchi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 93,
                              state_id: 5,
                              country_id: 160,
                              name: 'Bogoro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 94,
                              state_id: 5,
                              country_id: 160,
                              name: 'Damban',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 95,
                              state_id: 5,
                              country_id: 160,
                              name: 'Darazo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 96,
                              state_id: 5,
                              country_id: 160,
                              name: 'Dass',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 97,
                              state_id: 5,
                              country_id: 160,
                              name: 'Gamawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 98,
                              state_id: 5,
                              country_id: 160,
                              name: 'Ganjuwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 99,
                              state_id: 5,
                              country_id: 160,
                              name: 'Giade',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 100,
                              state_id: 5,
                              country_id: 160,
                              name: 'Itas/Gadau',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 101,
                              state_id: 5,
                              country_id: 160,
                              name: 'Jama\'are',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 102,
                              state_id: 5,
                              country_id: 160,
                              name: 'Katagum',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 103,
                              state_id: 5,
                              country_id: 160,
                              name: 'Kirfi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 104,
                              state_id: 5,
                              country_id: 160,
                              name: 'Misau',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 105,
                              state_id: 5,
                              country_id: 160,
                              name: 'Ningi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 106,
                              state_id: 5,
                              country_id: 160,
                              name: 'Shira',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 107,
                              state_id: 5,
                              country_id: 160,
                              name: 'Tafawa Balewa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 108,
                              state_id: 5,
                              country_id: 160,
                              name: 'Toro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 109,
                              state_id: 5,
                              country_id: 160,
                              name: 'Warji',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 110,
                              state_id: 5,
                              country_id: 160,
                              name: 'Zaki',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 6,
                      name: 'Bayelsa',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 111,
                              state_id: 6,
                              country_id: 160,
                              name: 'Brass',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 112,
                              state_id: 6,
                              country_id: 160,
                              name: 'Ekeremor',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 113,
                              state_id: 6,
                              country_id: 160,
                              name: 'Kolokuma/Opokuma',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 114,
                              state_id: 6,
                              country_id: 160,
                              name: 'Nembe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 115,
                              state_id: 6,
                              country_id: 160,
                              name: 'Ogbia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 116,
                              state_id: 6,
                              country_id: 160,
                              name: 'Sagbama',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 117,
                              state_id: 6,
                              country_id: 160,
                              name: 'Southern Ijaw',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 118,
                              state_id: 6,
                              country_id: 160,
                              name: 'Yenagoa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 7,
                      name: 'Benue',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 119,
                              state_id: 7,
                              country_id: 160,
                              name: 'Ado',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 120,
                              state_id: 7,
                              country_id: 160,
                              name: 'Agatu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 121,
                              state_id: 7,
                              country_id: 160,
                              name: 'Apa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 122,
                              state_id: 7,
                              country_id: 160,
                              name: 'Buruku',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 123,
                              state_id: 7,
                              country_id: 160,
                              name: 'Gboko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 124,
                              state_id: 7,
                              country_id: 160,
                              name: 'Guma',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 125,
                              state_id: 7,
                              country_id: 160,
                              name: 'Gwer East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 126,
                              state_id: 7,
                              country_id: 160,
                              name: 'Gwer West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 127,
                              state_id: 7,
                              country_id: 160,
                              name: 'Katsina-Ala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 128,
                              state_id: 7,
                              country_id: 160,
                              name: 'Konshisha',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 129,
                              state_id: 7,
                              country_id: 160,
                              name: 'Kwande',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 130,
                              state_id: 7,
                              country_id: 160,
                              name: 'Logo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 131,
                              state_id: 7,
                              country_id: 160,
                              name: 'Makurdi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 132,
                              state_id: 7,
                              country_id: 160,
                              name: 'Obi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 133,
                              state_id: 7,
                              country_id: 160,
                              name: 'Ogbadibo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 134,
                              state_id: 7,
                              country_id: 160,
                              name: 'Ohimini',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 135,
                              state_id: 7,
                              country_id: 160,
                              name: 'Oju',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 136,
                              state_id: 7,
                              country_id: 160,
                              name: 'Okpokwu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 137,
                              state_id: 7,
                              country_id: 160,
                              name: 'Oturkpo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 138,
                              state_id: 7,
                              country_id: 160,
                              name: 'Tarka',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 139,
                              state_id: 7,
                              country_id: 160,
                              name: 'Ukum',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 140,
                              state_id: 7,
                              country_id: 160,
                              name: 'Ushongo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 141,
                              state_id: 7,
                              country_id: 160,
                              name: 'Vandeikya',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 8,
                      name: 'Borno',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 142,
                              state_id: 8,
                              country_id: 160,
                              name: 'Abadam',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 143,
                              state_id: 8,
                              country_id: 160,
                              name: 'Askira/Uba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 144,
                              state_id: 8,
                              country_id: 160,
                              name: 'Bama',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 145,
                              state_id: 8,
                              country_id: 160,
                              name: 'Bayo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 146,
                              state_id: 8,
                              country_id: 160,
                              name: 'Biu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 147,
                              state_id: 8,
                              country_id: 160,
                              name: 'Chibok',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 148,
                              state_id: 8,
                              country_id: 160,
                              name: 'Damboa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 149,
                              state_id: 8,
                              country_id: 160,
                              name: 'Dikwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 150,
                              state_id: 8,
                              country_id: 160,
                              name: 'Gubio',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 151,
                              state_id: 8,
                              country_id: 160,
                              name: 'Guzamala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 152,
                              state_id: 8,
                              country_id: 160,
                              name: 'Gwoza',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 153,
                              state_id: 8,
                              country_id: 160,
                              name: 'Hawul',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 154,
                              state_id: 8,
                              country_id: 160,
                              name: 'Jere',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 155,
                              state_id: 8,
                              country_id: 160,
                              name: 'Kaga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 156,
                              state_id: 8,
                              country_id: 160,
                              name: 'Kala/Balge',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 157,
                              state_id: 8,
                              country_id: 160,
                              name: 'Konduga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 158,
                              state_id: 8,
                              country_id: 160,
                              name: 'Kukawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 159,
                              state_id: 8,
                              country_id: 160,
                              name: 'Kwaya Kusar',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 160,
                              state_id: 8,
                              country_id: 160,
                              name: 'Mafa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 161,
                              state_id: 8,
                              country_id: 160,
                              name: 'Magumeri',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 162,
                              state_id: 8,
                              country_id: 160,
                              name: 'Maiduguri',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 163,
                              state_id: 8,
                              country_id: 160,
                              name: 'Marte',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 164,
                              state_id: 8,
                              country_id: 160,
                              name: 'Mobbar',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 165,
                              state_id: 8,
                              country_id: 160,
                              name: 'Monguno',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 166,
                              state_id: 8,
                              country_id: 160,
                              name: 'Ngala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 167,
                              state_id: 8,
                              country_id: 160,
                              name: 'Nganzai',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 168,
                              state_id: 8,
                              country_id: 160,
                              name: 'Shani',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 9,
                      name: 'Cross River',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 169,
                              state_id: 9,
                              country_id: 160,
                              name: 'Abi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 170,
                              state_id: 9,
                              country_id: 160,
                              name: 'Akamkpa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 171,
                              state_id: 9,
                              country_id: 160,
                              name: 'Akpabuyo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 172,
                              state_id: 9,
                              country_id: 160,
                              name: 'Bakassi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 173,
                              state_id: 9,
                              country_id: 160,
                              name: 'Bekwarra',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 174,
                              state_id: 9,
                              country_id: 160,
                              name: 'Biase',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 175,
                              state_id: 9,
                              country_id: 160,
                              name: 'Boki',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 176,
                              state_id: 9,
                              country_id: 160,
                              name: 'Calabar Municipal',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 177,
                              state_id: 9,
                              country_id: 160,
                              name: 'Calabar South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 178,
                              state_id: 9,
                              country_id: 160,
                              name: 'Etung',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 179,
                              state_id: 9,
                              country_id: 160,
                              name: 'Ikom',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 180,
                              state_id: 9,
                              country_id: 160,
                              name: 'Obanliku',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 181,
                              state_id: 9,
                              country_id: 160,
                              name: 'Obubra',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 182,
                              state_id: 9,
                              country_id: 160,
                              name: 'Obudu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 183,
                              state_id: 9,
                              country_id: 160,
                              name: 'Odukpani',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 184,
                              state_id: 9,
                              country_id: 160,
                              name: 'Ogoja',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 185,
                              state_id: 9,
                              country_id: 160,
                              name: 'Yakuur',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 186,
                              state_id: 9,
                              country_id: 160,
                              name: 'Yala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 10,
                      name: 'Delta',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 187,
                              state_id: 10,
                              country_id: 160,
                              name: 'Aniocha North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 188,
                              state_id: 10,
                              country_id: 160,
                              name: 'Aniocha South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 189,
                              state_id: 10,
                              country_id: 160,
                              name: 'Bomadi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 190,
                              state_id: 10,
                              country_id: 160,
                              name: 'Burutu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 191,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ethiope East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 192,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ethiope West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 193,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ika North East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 194,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ika South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 195,
                              state_id: 10,
                              country_id: 160,
                              name: 'Isoko North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 196,
                              state_id: 10,
                              country_id: 160,
                              name: 'Isoko South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 197,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ndokwa East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 198,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ndokwa West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 199,
                              state_id: 10,
                              country_id: 160,
                              name: 'Okpe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 200,
                              state_id: 10,
                              country_id: 160,
                              name: 'Oshimili North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 201,
                              state_id: 10,
                              country_id: 160,
                              name: 'Oshimili South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 202,
                              state_id: 10,
                              country_id: 160,
                              name: 'Patani',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 203,
                              state_id: 10,
                              country_id: 160,
                              name: 'Sapele',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 204,
                              state_id: 10,
                              country_id: 160,
                              name: 'Udu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 205,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ughelli North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 206,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ughelli South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 207,
                              state_id: 10,
                              country_id: 160,
                              name: 'Ukwuani',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 208,
                              state_id: 10,
                              country_id: 160,
                              name: 'Uvwie',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 209,
                              state_id: 10,
                              country_id: 160,
                              name: 'Warri North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 210,
                              state_id: 10,
                              country_id: 160,
                              name: 'Warri South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 211,
                              state_id: 10,
                              country_id: 160,
                              name: 'Warri South West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 11,
                      name: 'Ebonyi',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 212,
                              state_id: 11,
                              country_id: 160,
                              name: 'Abakaliki',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 213,
                              state_id: 11,
                              country_id: 160,
                              name: 'Afikpo North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 214,
                              state_id: 11,
                              country_id: 160,
                              name: 'Afikpo South (Edda)',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 215,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ebonyi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 216,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ezza North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 217,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ezza South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 218,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ikwo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 219,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ishielu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 220,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ivo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 221,
                              state_id: 11,
                              country_id: 160,
                              name: 'Izzi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 222,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ohaozara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 223,
                              state_id: 11,
                              country_id: 160,
                              name: 'Ohaukwu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 224,
                              state_id: 11,
                              country_id: 160,
                              name: 'Onicha',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 12,
                      name: 'Edo',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 225,
                              state_id: 12,
                              country_id: 160,
                              name: 'Akoko-Edo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 226,
                              state_id: 12,
                              country_id: 160,
                              name: 'Egor',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 227,
                              state_id: 12,
                              country_id: 160,
                              name: 'Esan Central',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 228,
                              state_id: 12,
                              country_id: 160,
                              name: 'Esan North-East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 229,
                              state_id: 12,
                              country_id: 160,
                              name: 'Esan South-East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 230,
                              state_id: 12,
                              country_id: 160,
                              name: 'Esan West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 231,
                              state_id: 12,
                              country_id: 160,
                              name: 'Etsako Central',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 232,
                              state_id: 12,
                              country_id: 160,
                              name: 'Etsako East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 233,
                              state_id: 12,
                              country_id: 160,
                              name: 'Etsako West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 234,
                              state_id: 12,
                              country_id: 160,
                              name: 'Igueben',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 235,
                              state_id: 12,
                              country_id: 160,
                              name: 'Ikpoba Okha',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 236,
                              state_id: 12,
                              country_id: 160,
                              name: 'Oredo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 237,
                              state_id: 12,
                              country_id: 160,
                              name: 'Orhionmwon',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 238,
                              state_id: 12,
                              country_id: 160,
                              name: 'Ovia North-East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 239,
                              state_id: 12,
                              country_id: 160,
                              name: 'Ovia South-West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 240,
                              state_id: 12,
                              country_id: 160,
                              name: 'Owan East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 241,
                              state_id: 12,
                              country_id: 160,
                              name: 'Owan West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 242,
                              state_id: 12,
                              country_id: 160,
                              name: 'Uhunmwonde',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 13,
                      name: 'Ekiti',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 243,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ado Ekiti',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 244,
                              state_id: 13,
                              country_id: 160,
                              name: 'Efon',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 245,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ekiti East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 246,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ekiti South-West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 247,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ekiti West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 248,
                              state_id: 13,
                              country_id: 160,
                              name: 'Emure',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 249,
                              state_id: 13,
                              country_id: 160,
                              name: 'Gbonyin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 250,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ido Osi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 251,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ijero',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 252,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ikere',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 253,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ikole',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 254,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ilejemeje',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 255,
                              state_id: 13,
                              country_id: 160,
                              name: 'Irepodun/Ifelodun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 256,
                              state_id: 13,
                              country_id: 160,
                              name: 'Ise/Orun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 257,
                              state_id: 13,
                              country_id: 160,
                              name: 'Moba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 258,
                              state_id: 13,
                              country_id: 160,
                              name: 'Oye',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 14,
                      name: 'Enugu',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 259,
                              state_id: 14,
                              country_id: 160,
                              name: 'Aninri',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 260,
                              state_id: 14,
                              country_id: 160,
                              name: 'Awgu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 261,
                              state_id: 14,
                              country_id: 160,
                              name: 'Enugu East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 262,
                              state_id: 14,
                              country_id: 160,
                              name: 'Enugu North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 263,
                              state_id: 14,
                              country_id: 160,
                              name: 'Enugu South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 264,
                              state_id: 14,
                              country_id: 160,
                              name: 'Ezeagu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 265,
                              state_id: 14,
                              country_id: 160,
                              name: 'Igbo Etiti',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 266,
                              state_id: 14,
                              country_id: 160,
                              name: 'Igbo Eze North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 267,
                              state_id: 14,
                              country_id: 160,
                              name: 'Igbo Eze South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 268,
                              state_id: 14,
                              country_id: 160,
                              name: 'Isi Uzo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 269,
                              state_id: 14,
                              country_id: 160,
                              name: 'Nkanu East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 270,
                              state_id: 14,
                              country_id: 160,
                              name: 'Nkanu West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 271,
                              state_id: 14,
                              country_id: 160,
                              name: 'Nsukka',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 272,
                              state_id: 14,
                              country_id: 160,
                              name: 'Oji River',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 273,
                              state_id: 14,
                              country_id: 160,
                              name: 'Udenu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 274,
                              state_id: 14,
                              country_id: 160,
                              name: 'Udi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 275,
                              state_id: 14,
                              country_id: 160,
                              name: 'Uzo Uwani',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 15,
                      name: 'Gombe',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 282,
                              state_id: 15,
                              country_id: 160,
                              name: 'Akko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 283,
                              state_id: 15,
                              country_id: 160,
                              name: 'Balanga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 284,
                              state_id: 15,
                              country_id: 160,
                              name: 'Billiri',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 285,
                              state_id: 15,
                              country_id: 160,
                              name: 'Dukku',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 286,
                              state_id: 15,
                              country_id: 160,
                              name: 'Funakaye',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 287,
                              state_id: 15,
                              country_id: 160,
                              name: 'Gombe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 288,
                              state_id: 15,
                              country_id: 160,
                              name: 'Kaltungo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 289,
                              state_id: 15,
                              country_id: 160,
                              name: 'Kwami',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 290,
                              state_id: 15,
                              country_id: 160,
                              name: 'Nafada',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 291,
                              state_id: 15,
                              country_id: 160,
                              name: 'Shongom',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 292,
                              state_id: 15,
                              country_id: 160,
                              name: 'Yamaltu/Deba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 16,
                      name: 'Imo',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 293,
                              state_id: 16,
                              country_id: 160,
                              name: 'Aboh Mbaise',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 294,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ahiazu Mbaise',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 295,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ehime Mbano',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 296,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ezinihitte',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 297,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ideato North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 298,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ideato South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 299,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ihitte/Uboma',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 300,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ikeduru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 301,
                              state_id: 16,
                              country_id: 160,
                              name: 'Isiala Mbano',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 302,
                              state_id: 16,
                              country_id: 160,
                              name: 'Isu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 303,
                              state_id: 16,
                              country_id: 160,
                              name: 'Mbaitoli',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 304,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ngor Okpala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 305,
                              state_id: 16,
                              country_id: 160,
                              name: 'Njaba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 306,
                              state_id: 16,
                              country_id: 160,
                              name: 'Nkwerre',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 307,
                              state_id: 16,
                              country_id: 160,
                              name: 'Nwangele',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 308,
                              state_id: 16,
                              country_id: 160,
                              name: 'Obowo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 309,
                              state_id: 16,
                              country_id: 160,
                              name: 'Oguta',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 310,
                              state_id: 16,
                              country_id: 160,
                              name: 'Ohaji/Egbema',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 311,
                              state_id: 16,
                              country_id: 160,
                              name: 'Okigwe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 312,
                              state_id: 16,
                              country_id: 160,
                              name: 'Orlu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 313,
                              state_id: 16,
                              country_id: 160,
                              name: 'Orsu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 314,
                              state_id: 16,
                              country_id: 160,
                              name: 'Oru East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 315,
                              state_id: 16,
                              country_id: 160,
                              name: 'Oru West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 316,
                              state_id: 16,
                              country_id: 160,
                              name: 'Owerri Municipal',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 317,
                              state_id: 16,
                              country_id: 160,
                              name: 'Owerri North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 318,
                              state_id: 16,
                              country_id: 160,
                              name: 'Owerri West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 319,
                              state_id: 16,
                              country_id: 160,
                              name: 'Unuimo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 17,
                      name: 'Jigawa',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 320,
                              state_id: 17,
                              country_id: 160,
                              name: 'Auyo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 321,
                              state_id: 17,
                              country_id: 160,
                              name: 'Babura',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 322,
                              state_id: 17,
                              country_id: 160,
                              name: 'Biriniwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 323,
                              state_id: 17,
                              country_id: 160,
                              name: 'Birnin Kudu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 324,
                              state_id: 17,
                              country_id: 160,
                              name: 'Buji',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 325,
                              state_id: 17,
                              country_id: 160,
                              name: 'Dutse',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 326,
                              state_id: 17,
                              country_id: 160,
                              name: 'Gagarawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 327,
                              state_id: 17,
                              country_id: 160,
                              name: 'Garki',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 328,
                              state_id: 17,
                              country_id: 160,
                              name: 'Gumel',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 329,
                              state_id: 17,
                              country_id: 160,
                              name: 'Guri',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 330,
                              state_id: 17,
                              country_id: 160,
                              name: 'Gwaram',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 331,
                              state_id: 17,
                              country_id: 160,
                              name: 'Gwiwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 332,
                              state_id: 17,
                              country_id: 160,
                              name: 'Hadejia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 333,
                              state_id: 17,
                              country_id: 160,
                              name: 'Jahun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 334,
                              state_id: 17,
                              country_id: 160,
                              name: 'Kafin Hausa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 335,
                              state_id: 17,
                              country_id: 160,
                              name: 'Kaugama',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 336,
                              state_id: 17,
                              country_id: 160,
                              name: 'Kazaure',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 337,
                              state_id: 17,
                              country_id: 160,
                              name: 'Kiri Kasama',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 338,
                              state_id: 17,
                              country_id: 160,
                              name: 'Kiyawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 339,
                              state_id: 17,
                              country_id: 160,
                              name: 'Maigatari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 340,
                              state_id: 17,
                              country_id: 160,
                              name: 'Malam Madori',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 341,
                              state_id: 17,
                              country_id: 160,
                              name: 'Miga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 342,
                              state_id: 17,
                              country_id: 160,
                              name: 'Ringim',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 343,
                              state_id: 17,
                              country_id: 160,
                              name: 'Roni',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 344,
                              state_id: 17,
                              country_id: 160,
                              name: 'Sule Tankarkar',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 345,
                              state_id: 17,
                              country_id: 160,
                              name: 'Taura',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 346,
                              state_id: 17,
                              country_id: 160,
                              name: 'Yankwashi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 18,
                      name: 'Kaduna',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 347,
                              state_id: 18,
                              country_id: 160,
                              name: 'Birnin Gwari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 348,
                              state_id: 18,
                              country_id: 160,
                              name: 'Chikun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 349,
                              state_id: 18,
                              country_id: 160,
                              name: 'Giwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 350,
                              state_id: 18,
                              country_id: 160,
                              name: 'Igabi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 351,
                              state_id: 18,
                              country_id: 160,
                              name: 'Ikara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 352,
                              state_id: 18,
                              country_id: 160,
                              name: 'Jaba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 353,
                              state_id: 18,
                              country_id: 160,
                              name: 'Jema\'a',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 354,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kachia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 355,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kaduna North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 356,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kaduna South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 357,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kagarko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 358,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kajuru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 359,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kaura',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 360,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kauru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 361,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kubau',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 362,
                              state_id: 18,
                              country_id: 160,
                              name: 'Kudan',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 363,
                              state_id: 18,
                              country_id: 160,
                              name: 'Lere',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 364,
                              state_id: 18,
                              country_id: 160,
                              name: 'Makarfi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 365,
                              state_id: 18,
                              country_id: 160,
                              name: 'Sabon Gari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 366,
                              state_id: 18,
                              country_id: 160,
                              name: 'Sanga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 367,
                              state_id: 18,
                              country_id: 160,
                              name: 'Soba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 368,
                              state_id: 18,
                              country_id: 160,
                              name: 'Zangon Kataf',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 369,
                              state_id: 18,
                              country_id: 160,
                              name: 'Zaria',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 19,
                      name: 'Kano',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 370,
                              state_id: 19,
                              country_id: 160,
                              name: 'Ajingi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 371,
                              state_id: 19,
                              country_id: 160,
                              name: 'Albasu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 372,
                              state_id: 19,
                              country_id: 160,
                              name: 'Bagwai',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 373,
                              state_id: 19,
                              country_id: 160,
                              name: 'Bebeji',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 374,
                              state_id: 19,
                              country_id: 160,
                              name: 'Bichi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 375,
                              state_id: 19,
                              country_id: 160,
                              name: 'Bunkure',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 376,
                              state_id: 19,
                              country_id: 160,
                              name: 'Dala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 377,
                              state_id: 19,
                              country_id: 160,
                              name: 'Dambatta',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 378,
                              state_id: 19,
                              country_id: 160,
                              name: 'Dawakin Kudu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 379,
                              state_id: 19,
                              country_id: 160,
                              name: 'Dawakin Tofa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 380,
                              state_id: 19,
                              country_id: 160,
                              name: 'Doguwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 381,
                              state_id: 19,
                              country_id: 160,
                              name: 'Fagge',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 382,
                              state_id: 19,
                              country_id: 160,
                              name: 'Gabasawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 383,
                              state_id: 19,
                              country_id: 160,
                              name: 'Garko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 384,
                              state_id: 19,
                              country_id: 160,
                              name: 'Garun Mallam',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 385,
                              state_id: 19,
                              country_id: 160,
                              name: 'Gaya',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 386,
                              state_id: 19,
                              country_id: 160,
                              name: 'Gezawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 387,
                              state_id: 19,
                              country_id: 160,
                              name: 'Gwale',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 388,
                              state_id: 19,
                              country_id: 160,
                              name: 'Gwarzo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 389,
                              state_id: 19,
                              country_id: 160,
                              name: 'Kabo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 390,
                              state_id: 19,
                              country_id: 160,
                              name: 'Kano Municipal',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 391,
                              state_id: 19,
                              country_id: 160,
                              name: 'Karaye',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 392,
                              state_id: 19,
                              country_id: 160,
                              name: 'Kibiya',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 393,
                              state_id: 19,
                              country_id: 160,
                              name: 'Kiru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 394,
                              state_id: 19,
                              country_id: 160,
                              name: 'Kumbotso',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 395,
                              state_id: 19,
                              country_id: 160,
                              name: 'Kunchi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 396,
                              state_id: 19,
                              country_id: 160,
                              name: 'Kura',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 397,
                              state_id: 19,
                              country_id: 160,
                              name: 'Madobi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 398,
                              state_id: 19,
                              country_id: 160,
                              name: 'Makoda',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 399,
                              state_id: 19,
                              country_id: 160,
                              name: 'Minjibir',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 400,
                              state_id: 19,
                              country_id: 160,
                              name: 'Nasarawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 401,
                              state_id: 19,
                              country_id: 160,
                              name: 'Rano',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 402,
                              state_id: 19,
                              country_id: 160,
                              name: 'Rimin Gado',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 403,
                              state_id: 19,
                              country_id: 160,
                              name: 'Rogo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 404,
                              state_id: 19,
                              country_id: 160,
                              name: 'Shanono',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 405,
                              state_id: 19,
                              country_id: 160,
                              name: 'Sumaila',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 406,
                              state_id: 19,
                              country_id: 160,
                              name: 'Takai',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 407,
                              state_id: 19,
                              country_id: 160,
                              name: 'Tarauni',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 408,
                              state_id: 19,
                              country_id: 160,
                              name: 'Tofa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 409,
                              state_id: 19,
                              country_id: 160,
                              name: 'Tsanyawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 410,
                              state_id: 19,
                              country_id: 160,
                              name: 'Tudun Wada',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 411,
                              state_id: 19,
                              country_id: 160,
                              name: 'Ungogo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 412,
                              state_id: 19,
                              country_id: 160,
                              name: 'Warawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 413,
                              state_id: 19,
                              country_id: 160,
                              name: 'Wudil',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 20,
                      name: 'Katsina',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 414,
                              state_id: 20,
                              country_id: 160,
                              name: 'Bakori',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 415,
                              state_id: 20,
                              country_id: 160,
                              name: 'Batagarawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 416,
                              state_id: 20,
                              country_id: 160,
                              name: 'Batsari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 417,
                              state_id: 20,
                              country_id: 160,
                              name: 'Baure',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 418,
                              state_id: 20,
                              country_id: 160,
                              name: 'Bindawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 419,
                              state_id: 20,
                              country_id: 160,
                              name: 'Charanchi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 420,
                              state_id: 20,
                              country_id: 160,
                              name: 'Dan Musa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 421,
                              state_id: 20,
                              country_id: 160,
                              name: 'Dandume',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 422,
                              state_id: 20,
                              country_id: 160,
                              name: 'Danja',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 423,
                              state_id: 20,
                              country_id: 160,
                              name: 'Daura',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 424,
                              state_id: 20,
                              country_id: 160,
                              name: 'Dutsi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 425,
                              state_id: 20,
                              country_id: 160,
                              name: 'Dutsin Ma',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 426,
                              state_id: 20,
                              country_id: 160,
                              name: 'Faskari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 427,
                              state_id: 20,
                              country_id: 160,
                              name: 'Funtua',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 428,
                              state_id: 20,
                              country_id: 160,
                              name: 'Ingawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 429,
                              state_id: 20,
                              country_id: 160,
                              name: 'Jibia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 430,
                              state_id: 20,
                              country_id: 160,
                              name: 'Kafur',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 431,
                              state_id: 20,
                              country_id: 160,
                              name: 'Kaita',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 432,
                              state_id: 20,
                              country_id: 160,
                              name: 'Kankara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 433,
                              state_id: 20,
                              country_id: 160,
                              name: 'Kankia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 434,
                              state_id: 20,
                              country_id: 160,
                              name: 'Katsina',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 435,
                              state_id: 20,
                              country_id: 160,
                              name: 'Kurfi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 436,
                              state_id: 20,
                              country_id: 160,
                              name: 'Kusada',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 437,
                              state_id: 20,
                              country_id: 160,
                              name: 'Mai\'Adua',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 438,
                              state_id: 20,
                              country_id: 160,
                              name: 'Malumfashi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 439,
                              state_id: 20,
                              country_id: 160,
                              name: 'Mani',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 440,
                              state_id: 20,
                              country_id: 160,
                              name: 'Mashi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 441,
                              state_id: 20,
                              country_id: 160,
                              name: 'Matazu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 442,
                              state_id: 20,
                              country_id: 160,
                              name: 'Musawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 443,
                              state_id: 20,
                              country_id: 160,
                              name: 'Rimi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 444,
                              state_id: 20,
                              country_id: 160,
                              name: 'Sabuwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 445,
                              state_id: 20,
                              country_id: 160,
                              name: 'Safana',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 446,
                              state_id: 20,
                              country_id: 160,
                              name: 'Sandamu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 447,
                              state_id: 20,
                              country_id: 160,
                              name: 'Zango',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 21,
                      name: 'Kebbi',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 448,
                              state_id: 21,
                              country_id: 160,
                              name: 'Aleiro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 449,
                              state_id: 21,
                              country_id: 160,
                              name: 'Arewa Dandi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 450,
                              state_id: 21,
                              country_id: 160,
                              name: 'Argungu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 451,
                              state_id: 21,
                              country_id: 160,
                              name: 'Augie',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 452,
                              state_id: 21,
                              country_id: 160,
                              name: 'Bagudo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 453,
                              state_id: 21,
                              country_id: 160,
                              name: 'Birnin Kebbi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 454,
                              state_id: 21,
                              country_id: 160,
                              name: 'Bunza',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 455,
                              state_id: 21,
                              country_id: 160,
                              name: 'Dandi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 456,
                              state_id: 21,
                              country_id: 160,
                              name: 'Fakai',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 457,
                              state_id: 21,
                              country_id: 160,
                              name: 'Gwandu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 458,
                              state_id: 21,
                              country_id: 160,
                              name: 'Jega',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 459,
                              state_id: 21,
                              country_id: 160,
                              name: 'Kalgo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 460,
                              state_id: 21,
                              country_id: 160,
                              name: 'Koko/Besse',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 461,
                              state_id: 21,
                              country_id: 160,
                              name: 'Maiyama',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 462,
                              state_id: 21,
                              country_id: 160,
                              name: 'Ngaski',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 463,
                              state_id: 21,
                              country_id: 160,
                              name: 'Sakaba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 464,
                              state_id: 21,
                              country_id: 160,
                              name: 'Shanga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 465,
                              state_id: 21,
                              country_id: 160,
                              name: 'Suru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 466,
                              state_id: 21,
                              country_id: 160,
                              name: 'Wasagu/Danko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 467,
                              state_id: 21,
                              country_id: 160,
                              name: 'Yauri',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 468,
                              state_id: 21,
                              country_id: 160,
                              name: 'Zuru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 22,
                      name: 'Kogi',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 469,
                              state_id: 22,
                              country_id: 160,
                              name: 'Adavi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 470,
                              state_id: 22,
                              country_id: 160,
                              name: 'Ajaokuta',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 471,
                              state_id: 22,
                              country_id: 160,
                              name: 'Ankpa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 472,
                              state_id: 22,
                              country_id: 160,
                              name: 'Bassa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 473,
                              state_id: 22,
                              country_id: 160,
                              name: 'Dekina',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 474,
                              state_id: 22,
                              country_id: 160,
                              name: 'Ibaji',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 475,
                              state_id: 22,
                              country_id: 160,
                              name: 'Idah',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 476,
                              state_id: 22,
                              country_id: 160,
                              name: 'Igalamela Odolu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 477,
                              state_id: 22,
                              country_id: 160,
                              name: 'Ijumu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 478,
                              state_id: 22,
                              country_id: 160,
                              name: 'Kabba/Bunu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 479,
                              state_id: 22,
                              country_id: 160,
                              name: 'Kogi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 480,
                              state_id: 22,
                              country_id: 160,
                              name: 'Lokoja',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 481,
                              state_id: 22,
                              country_id: 160,
                              name: 'Mopa Muro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 482,
                              state_id: 22,
                              country_id: 160,
                              name: 'Ofu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 483,
                              state_id: 22,
                              country_id: 160,
                              name: 'Ogori/Magongo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 484,
                              state_id: 22,
                              country_id: 160,
                              name: 'Okehi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 485,
                              state_id: 22,
                              country_id: 160,
                              name: 'Okene',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 486,
                              state_id: 22,
                              country_id: 160,
                              name: 'Olamaboro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 487,
                              state_id: 22,
                              country_id: 160,
                              name: 'Omala',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 488,
                              state_id: 22,
                              country_id: 160,
                              name: 'Yagba East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 489,
                              state_id: 22,
                              country_id: 160,
                              name: 'Yagba West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 23,
                      name: 'Kwara',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 490,
                              state_id: 23,
                              country_id: 160,
                              name: 'Asa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 491,
                              state_id: 23,
                              country_id: 160,
                              name: 'Baruten',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 492,
                              state_id: 23,
                              country_id: 160,
                              name: 'Edu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 493,
                              state_id: 23,
                              country_id: 160,
                              name: 'Ekiti',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 494,
                              state_id: 23,
                              country_id: 160,
                              name: 'Ifelodun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 495,
                              state_id: 23,
                              country_id: 160,
                              name: 'Ilorin East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 496,
                              state_id: 23,
                              country_id: 160,
                              name: 'Ilorin South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 497,
                              state_id: 23,
                              country_id: 160,
                              name: 'Ilorin West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 498,
                              state_id: 23,
                              country_id: 160,
                              name: 'Irepodun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 499,
                              state_id: 23,
                              country_id: 160,
                              name: 'Isin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 500,
                              state_id: 23,
                              country_id: 160,
                              name: 'Kaiama',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 501,
                              state_id: 23,
                              country_id: 160,
                              name: 'Moro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 502,
                              state_id: 23,
                              country_id: 160,
                              name: 'Offa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 503,
                              state_id: 23,
                              country_id: 160,
                              name: 'Oke Ero',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 504,
                              state_id: 23,
                              country_id: 160,
                              name: 'Oyun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 505,
                              state_id: 23,
                              country_id: 160,
                              name: 'Pategi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 24,
                      name: 'Lagos',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 506,
                              state_id: 24,
                              country_id: 160,
                              name: 'Agege',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 507,
                              state_id: 24,
                              country_id: 160,
                              name: 'Ajeromi-Ifelodun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 508,
                              state_id: 24,
                              country_id: 160,
                              name: 'Alimosho',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 509,
                              state_id: 24,
                              country_id: 160,
                              name: 'Amuwo-Odofin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 510,
                              state_id: 24,
                              country_id: 160,
                              name: 'Apapa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 511,
                              state_id: 24,
                              country_id: 160,
                              name: 'Badagry',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 512,
                              state_id: 24,
                              country_id: 160,
                              name: 'Epe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 513,
                              state_id: 24,
                              country_id: 160,
                              name: 'Eti Osa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 514,
                              state_id: 24,
                              country_id: 160,
                              name: 'Ibeju-Lekki',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 515,
                              state_id: 24,
                              country_id: 160,
                              name: 'Ifako-Ijaiye',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 516,
                              state_id: 24,
                              country_id: 160,
                              name: 'Ikeja',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 517,
                              state_id: 24,
                              country_id: 160,
                              name: 'Ikorodu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 518,
                              state_id: 24,
                              country_id: 160,
                              name: 'Kosofe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 519,
                              state_id: 24,
                              country_id: 160,
                              name: 'Lagos Island',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 520,
                              state_id: 24,
                              country_id: 160,
                              name: 'Lagos Mainland',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 521,
                              state_id: 24,
                              country_id: 160,
                              name: 'Mushin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 522,
                              state_id: 24,
                              country_id: 160,
                              name: 'Ojo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 523,
                              state_id: 24,
                              country_id: 160,
                              name: 'Oshodi-Isolo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 524,
                              state_id: 24,
                              country_id: 160,
                              name: 'Shomolu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 525,
                              state_id: 24,
                              country_id: 160,
                              name: 'Surulere',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 25,
                      name: 'Nasarawa',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 526,
                              state_id: 25,
                              country_id: 160,
                              name: 'Akwanga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 527,
                              state_id: 25,
                              country_id: 160,
                              name: 'Awe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 528,
                              state_id: 25,
                              country_id: 160,
                              name: 'Doma',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 529,
                              state_id: 25,
                              country_id: 160,
                              name: 'Karu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 530,
                              state_id: 25,
                              country_id: 160,
                              name: 'Keana',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 531,
                              state_id: 25,
                              country_id: 160,
                              name: 'Keffi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 532,
                              state_id: 25,
                              country_id: 160,
                              name: 'Kokona',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 533,
                              state_id: 25,
                              country_id: 160,
                              name: 'Lafia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 534,
                              state_id: 25,
                              country_id: 160,
                              name: 'Nasarawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 535,
                              state_id: 25,
                              country_id: 160,
                              name: 'Nasarawa Egon',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 536,
                              state_id: 25,
                              country_id: 160,
                              name: 'Obi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 537,
                              state_id: 25,
                              country_id: 160,
                              name: 'Toto',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 538,
                              state_id: 25,
                              country_id: 160,
                              name: 'Wamba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 26,
                      name: 'Niger',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 539,
                              state_id: 26,
                              country_id: 160,
                              name: 'Agaie',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 540,
                              state_id: 26,
                              country_id: 160,
                              name: 'Agwara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 541,
                              state_id: 26,
                              country_id: 160,
                              name: 'Bida',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 542,
                              state_id: 26,
                              country_id: 160,
                              name: 'Borgu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 543,
                              state_id: 26,
                              country_id: 160,
                              name: 'Bosso',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 544,
                              state_id: 26,
                              country_id: 160,
                              name: 'Chanchaga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 545,
                              state_id: 26,
                              country_id: 160,
                              name: 'Edati',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 546,
                              state_id: 26,
                              country_id: 160,
                              name: 'Gbako',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 547,
                              state_id: 26,
                              country_id: 160,
                              name: 'Gurara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 548,
                              state_id: 26,
                              country_id: 160,
                              name: 'Katcha',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 549,
                              state_id: 26,
                              country_id: 160,
                              name: 'Kontagora',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 550,
                              state_id: 26,
                              country_id: 160,
                              name: 'Lapai',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 551,
                              state_id: 26,
                              country_id: 160,
                              name: 'Lavun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 552,
                              state_id: 26,
                              country_id: 160,
                              name: 'Magama',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 553,
                              state_id: 26,
                              country_id: 160,
                              name: 'Mariga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 554,
                              state_id: 26,
                              country_id: 160,
                              name: 'Mashegu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 555,
                              state_id: 26,
                              country_id: 160,
                              name: 'Mokwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 556,
                              state_id: 26,
                              country_id: 160,
                              name: 'Moya',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 557,
                              state_id: 26,
                              country_id: 160,
                              name: 'Paikoro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 558,
                              state_id: 26,
                              country_id: 160,
                              name: 'Rafi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 559,
                              state_id: 26,
                              country_id: 160,
                              name: 'Rijau',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 560,
                              state_id: 26,
                              country_id: 160,
                              name: 'Shiroro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 561,
                              state_id: 26,
                              country_id: 160,
                              name: 'Suleja',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 562,
                              state_id: 26,
                              country_id: 160,
                              name: 'Tafa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 563,
                              state_id: 26,
                              country_id: 160,
                              name: 'Wushishi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 27,
                      name: 'Ogun',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 564,
                              state_id: 27,
                              country_id: 160,
                              name: 'Abeokuta North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 565,
                              state_id: 27,
                              country_id: 160,
                              name: 'Abeokuta South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 566,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ado-Odo/Ota',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 567,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ewekoro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 568,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ifo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 569,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ijebu East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 570,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ijebu North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 571,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ijebu North East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 572,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ijebu Ode',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 573,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ikenne',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 574,
                              state_id: 27,
                              country_id: 160,
                              name: 'Imeko Afon',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 575,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ipokia',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 576,
                              state_id: 27,
                              country_id: 160,
                              name: 'Obafemi Owode',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 577,
                              state_id: 27,
                              country_id: 160,
                              name: 'Odeda',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 578,
                              state_id: 27,
                              country_id: 160,
                              name: 'Odogbolu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 579,
                              state_id: 27,
                              country_id: 160,
                              name: 'Ogun Waterside',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 580,
                              state_id: 27,
                              country_id: 160,
                              name: 'Remo North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 581,
                              state_id: 27,
                              country_id: 160,
                              name: 'Shagamu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 582,
                              state_id: 27,
                              country_id: 160,
                              name: 'Yewa North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 583,
                              state_id: 27,
                              country_id: 160,
                              name: 'Yewa South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 28,
                      name: 'Ondo',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 584,
                              state_id: 28,
                              country_id: 160,
                              name: 'Akoko North-East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 585,
                              state_id: 28,
                              country_id: 160,
                              name: 'Akoko North-West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 586,
                              state_id: 28,
                              country_id: 160,
                              name: 'Akoko South-East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 587,
                              state_id: 28,
                              country_id: 160,
                              name: 'Akoko South-West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 588,
                              state_id: 28,
                              country_id: 160,
                              name: 'Akure North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 589,
                              state_id: 28,
                              country_id: 160,
                              name: 'Akure South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 590,
                              state_id: 28,
                              country_id: 160,
                              name: 'Ese Odo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 591,
                              state_id: 28,
                              country_id: 160,
                              name: 'Idanre',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 592,
                              state_id: 28,
                              country_id: 160,
                              name: 'Ifedore',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 593,
                              state_id: 28,
                              country_id: 160,
                              name: 'Ilaje',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 594,
                              state_id: 28,
                              country_id: 160,
                              name: 'Ile Oluji/Okeigbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 595,
                              state_id: 28,
                              country_id: 160,
                              name: 'Irele',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 596,
                              state_id: 28,
                              country_id: 160,
                              name: 'Odigbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 597,
                              state_id: 28,
                              country_id: 160,
                              name: 'Okitipupa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 598,
                              state_id: 28,
                              country_id: 160,
                              name: 'Ondo East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 599,
                              state_id: 28,
                              country_id: 160,
                              name: 'Ondo West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 600,
                              state_id: 28,
                              country_id: 160,
                              name: 'Ose',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 601,
                              state_id: 28,
                              country_id: 160,
                              name: 'Owo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 29,
                      name: 'Osun',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 602,
                              state_id: 29,
                              country_id: 160,
                              name: 'Aiyedaade',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 603,
                              state_id: 29,
                              country_id: 160,
                              name: 'Aiyedire',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 604,
                              state_id: 29,
                              country_id: 160,
                              name: 'Atakunmosa East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 605,
                              state_id: 29,
                              country_id: 160,
                              name: 'Atakunmosa West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 606,
                              state_id: 29,
                              country_id: 160,
                              name: 'Boluwaduro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 607,
                              state_id: 29,
                              country_id: 160,
                              name: 'Boripe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 608,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ede North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 609,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ede South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 610,
                              state_id: 29,
                              country_id: 160,
                              name: 'Egbedore',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 611,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ejigbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 612,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ife Central',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 613,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ife East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 614,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ife North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 615,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ife South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 616,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ifedayo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 617,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ifelodun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 618,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ila',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 619,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ilesa East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 620,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ilesa West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 621,
                              state_id: 29,
                              country_id: 160,
                              name: 'Irepodun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 622,
                              state_id: 29,
                              country_id: 160,
                              name: 'Irewole',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 623,
                              state_id: 29,
                              country_id: 160,
                              name: 'Isokan',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 624,
                              state_id: 29,
                              country_id: 160,
                              name: 'Iwo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 625,
                              state_id: 29,
                              country_id: 160,
                              name: 'Obokun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 626,
                              state_id: 29,
                              country_id: 160,
                              name: 'Odo Otin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 627,
                              state_id: 29,
                              country_id: 160,
                              name: 'Ola Oluwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 628,
                              state_id: 29,
                              country_id: 160,
                              name: 'Olorunda',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 629,
                              state_id: 29,
                              country_id: 160,
                              name: 'Oriade',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 630,
                              state_id: 29,
                              country_id: 160,
                              name: 'Orolu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 631,
                              state_id: 29,
                              country_id: 160,
                              name: 'Osogbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 30,
                      name: 'Oyo',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 632,
                              state_id: 30,
                              country_id: 160,
                              name: 'Afijio',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 633,
                              state_id: 30,
                              country_id: 160,
                              name: 'Akinyele',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 634,
                              state_id: 30,
                              country_id: 160,
                              name: 'Atiba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 635,
                              state_id: 30,
                              country_id: 160,
                              name: 'Atisbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 636,
                              state_id: 30,
                              country_id: 160,
                              name: 'Egbeda',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 637,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibadan North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 638,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibadan North-East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 639,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibadan North-West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 640,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibadan South-East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 641,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibadan South-West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 642,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibarapa Central',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 643,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibarapa East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 644,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ibarapa North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 645,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ido',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 646,
                              state_id: 30,
                              country_id: 160,
                              name: 'Irepo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 647,
                              state_id: 30,
                              country_id: 160,
                              name: 'Iseyin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 648,
                              state_id: 30,
                              country_id: 160,
                              name: 'Itesiwaju',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 649,
                              state_id: 30,
                              country_id: 160,
                              name: 'Iwajowa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 650,
                              state_id: 30,
                              country_id: 160,
                              name: 'Kajola',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 651,
                              state_id: 30,
                              country_id: 160,
                              name: 'Lagelu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 652,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ogbomosho North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 653,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ogbomosho South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 654,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ogo Oluwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 655,
                              state_id: 30,
                              country_id: 160,
                              name: 'Olorunsogo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 656,
                              state_id: 30,
                              country_id: 160,
                              name: 'Oluyole',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 657,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ona Ara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 658,
                              state_id: 30,
                              country_id: 160,
                              name: 'Orelope',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 659,
                              state_id: 30,
                              country_id: 160,
                              name: 'Ori Ire',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 660,
                              state_id: 30,
                              country_id: 160,
                              name: 'Oyo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 661,
                              state_id: 30,
                              country_id: 160,
                              name: 'Oyo East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 662,
                              state_id: 30,
                              country_id: 160,
                              name: 'Saki East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 663,
                              state_id: 30,
                              country_id: 160,
                              name: 'Saki West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 664,
                              state_id: 30,
                              country_id: 160,
                              name: 'Surulere',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 31,
                      name: 'Plateau',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 665,
                              state_id: 31,
                              country_id: 160,
                              name: 'Barkin Ladi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 666,
                              state_id: 31,
                              country_id: 160,
                              name: 'Bassa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 667,
                              state_id: 31,
                              country_id: 160,
                              name: 'Bokkos',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 668,
                              state_id: 31,
                              country_id: 160,
                              name: 'Jos East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 669,
                              state_id: 31,
                              country_id: 160,
                              name: 'Jos North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 670,
                              state_id: 31,
                              country_id: 160,
                              name: 'Jos South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 671,
                              state_id: 31,
                              country_id: 160,
                              name: 'Kanam',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 672,
                              state_id: 31,
                              country_id: 160,
                              name: 'Kanke',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 673,
                              state_id: 31,
                              country_id: 160,
                              name: 'Langtang North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 674,
                              state_id: 31,
                              country_id: 160,
                              name: 'Langtang South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 675,
                              state_id: 31,
                              country_id: 160,
                              name: 'Mangu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 676,
                              state_id: 31,
                              country_id: 160,
                              name: 'Mikang',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 677,
                              state_id: 31,
                              country_id: 160,
                              name: 'Pankshin',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 678,
                              state_id: 31,
                              country_id: 160,
                              name: 'Qua\'an Pan',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 679,
                              state_id: 31,
                              country_id: 160,
                              name: 'Riyom',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 680,
                              state_id: 31,
                              country_id: 160,
                              name: 'Shendam',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 681,
                              state_id: 31,
                              country_id: 160,
                              name: 'Wase',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 32,
                      name: 'Rivers',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 682,
                              state_id: 32,
                              country_id: 160,
                              name: 'Abua/Odual',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 683,
                              state_id: 32,
                              country_id: 160,
                              name: 'Ahoada East',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 684,
                              state_id: 32,
                              country_id: 160,
                              name: 'Ahoada West',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 685,
                              state_id: 32,
                              country_id: 160,
                              name: 'Akuku-Toru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 686,
                              state_id: 32,
                              country_id: 160,
                              name: 'Andoni',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 687,
                              state_id: 32,
                              country_id: 160,
                              name: 'Asari-Toru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 688,
                              state_id: 32,
                              country_id: 160,
                              name: 'Bonny',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 689,
                              state_id: 32,
                              country_id: 160,
                              name: 'Degema',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 690,
                              state_id: 32,
                              country_id: 160,
                              name: 'Eleme',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 691,
                              state_id: 32,
                              country_id: 160,
                              name: 'Emuoha',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 692,
                              state_id: 32,
                              country_id: 160,
                              name: 'Etche',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 693,
                              state_id: 32,
                              country_id: 160,
                              name: 'Gokana',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 694,
                              state_id: 32,
                              country_id: 160,
                              name: 'Ikwerre',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 695,
                              state_id: 32,
                              country_id: 160,
                              name: 'Khana',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 696,
                              state_id: 32,
                              country_id: 160,
                              name: 'Obio/Akpor',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 697,
                              state_id: 32,
                              country_id: 160,
                              name: 'Ogba/Egbema/Ndoni',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 698,
                              state_id: 32,
                              country_id: 160,
                              name: 'Ogu/Bolo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 699,
                              state_id: 32,
                              country_id: 160,
                              name: 'Okrika',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 700,
                              state_id: 32,
                              country_id: 160,
                              name: 'Omuma',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 701,
                              state_id: 32,
                              country_id: 160,
                              name: 'Opobo/Nkoro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 702,
                              state_id: 32,
                              country_id: 160,
                              name: 'Oyigbo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 703,
                              state_id: 32,
                              country_id: 160,
                              name: 'Port Harcourt',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 704,
                              state_id: 32,
                              country_id: 160,
                              name: 'Tai',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 33,
                      name: 'Sokoto',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 705,
                              state_id: 33,
                              country_id: 160,
                              name: 'Binji',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 706,
                              state_id: 33,
                              country_id: 160,
                              name: 'Bodinga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 707,
                              state_id: 33,
                              country_id: 160,
                              name: 'Dange Shuni',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 708,
                              state_id: 33,
                              country_id: 160,
                              name: 'Gada',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 709,
                              state_id: 33,
                              country_id: 160,
                              name: 'Goronyo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 710,
                              state_id: 33,
                              country_id: 160,
                              name: 'Gudu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 711,
                              state_id: 33,
                              country_id: 160,
                              name: 'Gwadabawa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 712,
                              state_id: 33,
                              country_id: 160,
                              name: 'Illela',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 713,
                              state_id: 33,
                              country_id: 160,
                              name: 'Isa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 714,
                              state_id: 33,
                              country_id: 160,
                              name: 'Kebbe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 715,
                              state_id: 33,
                              country_id: 160,
                              name: 'Kware',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 716,
                              state_id: 33,
                              country_id: 160,
                              name: 'Rabah',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 717,
                              state_id: 33,
                              country_id: 160,
                              name: 'Sabon Birni',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 718,
                              state_id: 33,
                              country_id: 160,
                              name: 'Shagari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 719,
                              state_id: 33,
                              country_id: 160,
                              name: 'Silame',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 720,
                              state_id: 33,
                              country_id: 160,
                              name: 'Sokoto North',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 721,
                              state_id: 33,
                              country_id: 160,
                              name: 'Sokoto South',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 722,
                              state_id: 33,
                              country_id: 160,
                              name: 'Tambuwal',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 723,
                              state_id: 33,
                              country_id: 160,
                              name: 'Tangaza',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 724,
                              state_id: 33,
                              country_id: 160,
                              name: 'Tureta',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 725,
                              state_id: 33,
                              country_id: 160,
                              name: 'Wamako',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 726,
                              state_id: 33,
                              country_id: 160,
                              name: 'Wurno',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 727,
                              state_id: 33,
                              country_id: 160,
                              name: 'Yabo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 34,
                      name: 'Taraba',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 728,
                              state_id: 34,
                              country_id: 160,
                              name: 'Ardo Kola',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 729,
                              state_id: 34,
                              country_id: 160,
                              name: 'Bali',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 730,
                              state_id: 34,
                              country_id: 160,
                              name: 'Donga',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 731,
                              state_id: 34,
                              country_id: 160,
                              name: 'Gashaka',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 732,
                              state_id: 34,
                              country_id: 160,
                              name: 'Gassol',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 733,
                              state_id: 34,
                              country_id: 160,
                              name: 'Ibi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 734,
                              state_id: 34,
                              country_id: 160,
                              name: 'Jalingo',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 735,
                              state_id: 34,
                              country_id: 160,
                              name: 'Karim Lamido',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 736,
                              state_id: 34,
                              country_id: 160,
                              name: 'Kumi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 737,
                              state_id: 34,
                              country_id: 160,
                              name: 'Lau',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 738,
                              state_id: 34,
                              country_id: 160,
                              name: 'Sardauna',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 739,
                              state_id: 34,
                              country_id: 160,
                              name: 'Takum',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 740,
                              state_id: 34,
                              country_id: 160,
                              name: 'Ussa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 741,
                              state_id: 34,
                              country_id: 160,
                              name: 'Wukari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 742,
                              state_id: 34,
                              country_id: 160,
                              name: 'Yorro',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 743,
                              state_id: 34,
                              country_id: 160,
                              name: 'Zing',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 35,
                      name: 'Yobe',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 744,
                              state_id: 35,
                              country_id: 160,
                              name: 'Bade',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 745,
                              state_id: 35,
                              country_id: 160,
                              name: 'Bursari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 746,
                              state_id: 35,
                              country_id: 160,
                              name: 'Damaturu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 747,
                              state_id: 35,
                              country_id: 160,
                              name: 'Fika',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 748,
                              state_id: 35,
                              country_id: 160,
                              name: 'Fune',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 749,
                              state_id: 35,
                              country_id: 160,
                              name: 'Geidam',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 750,
                              state_id: 35,
                              country_id: 160,
                              name: 'Gujba',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 751,
                              state_id: 35,
                              country_id: 160,
                              name: 'Gulani',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 752,
                              state_id: 35,
                              country_id: 160,
                              name: 'Jakusko',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 753,
                              state_id: 35,
                              country_id: 160,
                              name: 'Karasuwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 754,
                              state_id: 35,
                              country_id: 160,
                              name: 'Machina',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 755,
                              state_id: 35,
                              country_id: 160,
                              name: 'Nangere',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 756,
                              state_id: 35,
                              country_id: 160,
                              name: 'Nguru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 757,
                              state_id: 35,
                              country_id: 160,
                              name: 'Potiskum',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 758,
                              state_id: 35,
                              country_id: 160,
                              name: 'Tarmuwa',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 759,
                              state_id: 35,
                              country_id: 160,
                              name: 'Yunusari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 760,
                              state_id: 35,
                              country_id: 160,
                              name: 'Yusufari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 36,
                      name: 'Zamfara',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 761,
                              state_id: 36,
                              country_id: 160,
                              name: 'Anka',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 762,
                              state_id: 36,
                              country_id: 160,
                              name: 'Bakura',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 763,
                              state_id: 36,
                              country_id: 160,
                              name: 'Birnin Magaji/Kiyaw',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 764,
                              state_id: 36,
                              country_id: 160,
                              name: 'Bukkuyum',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 765,
                              state_id: 36,
                              country_id: 160,
                              name: 'Bungudu',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 766,
                              state_id: 36,
                              country_id: 160,
                              name: 'Chafe',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 767,
                              state_id: 36,
                              country_id: 160,
                              name: 'Gummi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 768,
                              state_id: 36,
                              country_id: 160,
                              name: 'Gusau',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 769,
                              state_id: 36,
                              country_id: 160,
                              name: 'Kaura Namoda',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 770,
                              state_id: 36,
                              country_id: 160,
                              name: 'Maradun',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 771,
                              state_id: 36,
                              country_id: 160,
                              name: 'Maru',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 772,
                              state_id: 36,
                              country_id: 160,
                              name: 'Shinkafi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 773,
                              state_id: 36,
                              country_id: 160,
                              name: 'Talata Mafara',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 774,
                              state_id: 36,
                              country_id: 160,
                              name: 'Zurmi',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
                  {
                      id: 37,
                      name: 'FCT',
                      country_id: 160,
                      created_at: '2017-12-02T14:56:00.000000Z',
                      updated_at: '2017-12-02T14:56:00.000000Z',
                      lgas: [
                          {
                              id: 276,
                              state_id: 37,
                              country_id: 160,
                              name: 'Abaji',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 277,
                              state_id: 37,
                              country_id: 160,
                              name: 'Bwari',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 278,
                              state_id: 37,
                              country_id: 160,
                              name: 'Gwagwalada',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 279,
                              state_id: 37,
                              country_id: 160,
                              name: 'Kuje',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 280,
                              state_id: 37,
                              country_id: 160,
                              name: 'Kwali',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                          {
                              id: 281,
                              state_id: 37,
                              country_id: 160,
                              name: 'Municipal Area Council',
                              created_at: '2017-12-02T14:58:59.000000Z',
                              updated_at: '2017-12-02T14:58:59.000000Z',
                          },
                      ],
                  },
              ],
          },
          {
              id: 161,
              country_code: 'NU',
              country_name: 'Niue',
              states: [],
          },
          {
              id: 162,
              country_code: 'NF',
              country_name: 'Norfolk Island',
              states: [],
          },
          {
              id: 163,
              country_code: 'MP',
              country_name: 'Northern Mariana Islands',
              states: [],
          },
          {
              id: 164,
              country_code: 'NO',
              country_name: 'Norway',
              states: [],
          },
          {
              id: 165,
              country_code: 'OM',
              country_name: 'Oman',
              states: [],
          },
          {
              id: 166,
              country_code: 'PK',
              country_name: 'Pakistan',
              states: [],
          },
          {
              id: 167,
              country_code: 'PW',
              country_name: 'Palau',
              states: [],
          },
          {
              id: 168,
              country_code: 'PS',
              country_name: 'Palestinian Territory Occupied',
              states: [],
          },
          {
              id: 169,
              country_code: 'PA',
              country_name: 'Panama',
              states: [],
          },
          {
              id: 170,
              country_code: 'PG',
              country_name: 'Papua new Guinea',
              states: [],
          },
          {
              id: 171,
              country_code: 'PY',
              country_name: 'Paraguay',
              states: [],
          },
          {
              id: 172,
              country_code: 'PE',
              country_name: 'Peru',
              states: [],
          },
          {
              id: 173,
              country_code: 'PH',
              country_name: 'Philippines',
              states: [],
          },
          {
              id: 174,
              country_code: 'PN',
              country_name: 'Pitcairn Island',
              states: [],
          },
          {
              id: 175,
              country_code: 'PL',
              country_name: 'Poland',
              states: [],
          },
          {
              id: 176,
              country_code: 'PT',
              country_name: 'Portugal',
              states: [],
          },
          {
              id: 177,
              country_code: 'PR',
              country_name: 'Puerto Rico',
              states: [],
          },
          {
              id: 178,
              country_code: 'QA',
              country_name: 'Qatar',
              states: [],
          },
          {
              id: 179,
              country_code: 'RE',
              country_name: 'Reunion',
              states: [],
          },
          {
              id: 180,
              country_code: 'RO',
              country_name: 'Romania',
              states: [],
          },
          {
              id: 181,
              country_code: 'RU',
              country_name: 'Russia',
              states: [],
          },
          {
              id: 182,
              country_code: 'RW',
              country_name: 'Rwanda',
              states: [],
          },
          {
              id: 183,
              country_code: 'SH',
              country_name: 'Saint Helena',
              states: [],
          },
          {
              id: 184,
              country_code: 'KN',
              country_name: 'Saint Kitts And Nevis',
              states: [],
          },
          {
              id: 185,
              country_code: 'LC',
              country_name: 'Saint Lucia',
              states: [],
          },
          {
              id: 186,
              country_code: 'PM',
              country_name: 'Saint Pierre and Miquelon',
              states: [],
          },
          {
              id: 187,
              country_code: 'VC',
              country_name: 'Saint Vincent And The Grenadines',
              states: [],
          },
          {
              id: 188,
              country_code: 'WS',
              country_name: 'Samoa',
              states: [],
          },
          {
              id: 189,
              country_code: 'SM',
              country_name: 'San Marino',
              states: [],
          },
          {
              id: 190,
              country_code: 'ST',
              country_name: 'Sao Tome and Principe',
              states: [],
          },
          {
              id: 191,
              country_code: 'SA',
              country_name: 'Saudi Arabia',
              states: [],
          },
          {
              id: 192,
              country_code: 'SN',
              country_name: 'Senegal',
              states: [],
          },
          {
              id: 193,
              country_code: 'RS',
              country_name: 'Serbia',
              states: [],
          },
          {
              id: 194,
              country_code: 'SC',
              country_name: 'Seychelles',
              states: [],
          },
          {
              id: 195,
              country_code: 'SL',
              country_name: 'Sierra Leone',
              states: [],
          },
          {
              id: 196,
              country_code: 'SG',
              country_name: 'Singapore',
              states: [],
          },
          {
              id: 197,
              country_code: 'SK',
              country_name: 'Slovakia',
              states: [],
          },
          {
              id: 198,
              country_code: 'SI',
              country_name: 'Slovenia',
              states: [],
          },
          {
              id: 199,
              country_code: 'XG',
              country_name: 'Smaller Territories of the UK',
              states: [],
          },
          {
              id: 200,
              country_code: 'SB',
              country_name: 'Solomon Islands',
              states: [],
          },
          {
              id: 201,
              country_code: 'SO',
              country_name: 'Somalia',
              states: [],
          },
          {
              id: 202,
              country_code: 'ZA',
              country_name: 'South Africa',
              states: [],
          },
          {
              id: 203,
              country_code: 'GS',
              country_name: 'South Georgia',
              states: [],
          },
          {
              id: 204,
              country_code: 'SS',
              country_name: 'South Sudan',
              states: [],
          },
          {
              id: 205,
              country_code: 'ES',
              country_name: 'Spain',
              states: [],
          },
          {
              id: 206,
              country_code: 'LK',
              country_name: 'Sri Lanka',
              states: [],
          },
          {
              id: 207,
              country_code: 'SD',
              country_name: 'Sudan',
              states: [],
          },
          {
              id: 208,
              country_code: 'SR',
              country_name: 'Suriname',
              states: [],
          },
          {
              id: 209,
              country_code: 'SJ',
              country_name: 'Svalbard And Jan Mayen Islands',
              states: [],
          },
          {
              id: 210,
              country_code: 'SZ',
              country_name: 'Swaziland',
              states: [],
          },
          {
              id: 211,
              country_code: 'SE',
              country_name: 'Sweden',
              states: [],
          },
          {
              id: 212,
              country_code: 'CH',
              country_name: 'Switzerland',
              states: [],
          },
          {
              id: 213,
              country_code: 'SY',
              country_name: 'Syria',
              states: [],
          },
          {
              id: 214,
              country_code: 'TW',
              country_name: 'Taiwan',
              states: [],
          },
          {
              id: 215,
              country_code: 'TJ',
              country_name: 'Tajikistan',
              states: [],
          },
          {
              id: 216,
              country_code: 'TZ',
              country_name: 'Tanzania',
              states: [],
          },
          {
              id: 217,
              country_code: 'TH',
              country_name: 'Thailand',
              states: [],
          },
          {
              id: 218,
              country_code: 'TG',
              country_name: 'Togo',
              states: [],
          },
          {
              id: 219,
              country_code: 'TK',
              country_name: 'Tokelau',
              states: [],
          },
          {
              id: 220,
              country_code: 'TO',
              country_name: 'Tonga',
              states: [],
          },
          {
              id: 221,
              country_code: 'TT',
              country_name: 'Trinidad And Tobago',
              states: [],
          },
          {
              id: 222,
              country_code: 'TN',
              country_name: 'Tunisia',
              states: [],
          },
          {
              id: 223,
              country_code: 'TR',
              country_name: 'Turkey',
              states: [],
          },
          {
              id: 224,
              country_code: 'TM',
              country_name: 'Turkmenistan',
              states: [],
          },
          {
              id: 225,
              country_code: 'TC',
              country_name: 'Turks And Caicos Islands',
              states: [],
          },
          {
              id: 226,
              country_code: 'TV',
              country_name: 'Tuvalu',
              states: [],
          },
          {
              id: 227,
              country_code: 'UG',
              country_name: 'Uganda',
              states: [],
          },
          {
              id: 228,
              country_code: 'UA',
              country_name: 'Ukraine',
              states: [],
          },
          {
              id: 229,
              country_code: 'AE',
              country_name: 'United Arab Emirates',
              states: [],
          },
          {
              id: 230,
              country_code: 'GB',
              country_name: 'United Kingdom',
              states: [],
          },
          {
              id: 231,
              country_code: 'US',
              country_name: 'United States',
              states: [],
          },
          {
              id: 232,
              country_code: 'UM',
              country_name: 'United States Minor Outlying Islands',
              states: [],
          },
          {
              id: 233,
              country_code: 'UY',
              country_name: 'Uruguay',
              states: [],
          },
          {
              id: 234,
              country_code: 'UZ',
              country_name: 'Uzbekistan',
              states: [],
          },
          {
              id: 235,
              country_code: 'VU',
              country_name: 'Vanuatu',
              states: [],
          },
          {
              id: 236,
              country_code: 'VA',
              country_name: 'Vatican City State (Holy See)',
              states: [],
          },
          {
              id: 237,
              country_code: 'VE',
              country_name: 'Venezuela',
              states: [],
          },
          {
              id: 238,
              country_code: 'VN',
              country_name: 'Vietnam',
              states: [],
          },
          {
              id: 239,
              country_code: 'VG',
              country_name: 'Virgin Islands (British)',
              states: [],
          },
          {
              id: 240,
              country_code: 'VI',
              country_name: 'Virgin Islands (US)',
              states: [],
          },
          {
              id: 241,
              country_code: 'WF',
              country_name: 'Wallis And Futuna Islands',
              states: [],
          },
          {
              id: 242,
              country_code: 'EH',
              country_name: 'Western Sahara',
              states: [],
          },
          {
              id: 243,
              country_code: 'YE',
              country_name: 'Yemen',
              states: [],
          },
          {
              id: 244,
              country_code: 'YU',
              country_name: 'Yugoslavia',
              states: [],
          },
          {
              id: 245,
              country_code: 'ZM',
              country_name: 'Zambia',
              states: [],
          },
          {
              id: 246,
              country_code: 'ZW',
              country_name: 'Zimbabwe',
              states: [],
          },
      ],
      selected_country: {
          id: 160,
          country_code: 'NG',
          country_name: 'Nigeria',
          states: [
              {
                  id: 1,
                  name: 'Abia',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 1,
                          state_id: 1,
                          country_id: 160,
                          name: 'Aba North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 2,
                          state_id: 1,
                          country_id: 160,
                          name: 'Aba South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 3,
                          state_id: 1,
                          country_id: 160,
                          name: 'Arochukwu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 4,
                          state_id: 1,
                          country_id: 160,
                          name: 'Bende',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 5,
                          state_id: 1,
                          country_id: 160,
                          name: 'Ikwuano',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 6,
                          state_id: 1,
                          country_id: 160,
                          name: 'Isiala Ngwa North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 7,
                          state_id: 1,
                          country_id: 160,
                          name: 'Isiala Ngwa South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 8,
                          state_id: 1,
                          country_id: 160,
                          name: 'Isuikwuato',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 9,
                          state_id: 1,
                          country_id: 160,
                          name: 'Obi Ngwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 10,
                          state_id: 1,
                          country_id: 160,
                          name: 'Ohafia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 11,
                          state_id: 1,
                          country_id: 160,
                          name: 'Osisioma',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 12,
                          state_id: 1,
                          country_id: 160,
                          name: 'Ugwunagbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 13,
                          state_id: 1,
                          country_id: 160,
                          name: 'Ukwa East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 14,
                          state_id: 1,
                          country_id: 160,
                          name: 'Ukwa West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 15,
                          state_id: 1,
                          country_id: 160,
                          name: 'Umu Nneochi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 16,
                          state_id: 1,
                          country_id: 160,
                          name: 'Umuahia North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 17,
                          state_id: 1,
                          country_id: 160,
                          name: 'Umuahia South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 2,
                  name: 'Adamawa',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 18,
                          state_id: 2,
                          country_id: 160,
                          name: 'Demsa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 19,
                          state_id: 2,
                          country_id: 160,
                          name: 'Fufure',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 20,
                          state_id: 2,
                          country_id: 160,
                          name: 'Ganye',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 21,
                          state_id: 2,
                          country_id: 160,
                          name: 'Gayuk',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 22,
                          state_id: 2,
                          country_id: 160,
                          name: 'Gombi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 23,
                          state_id: 2,
                          country_id: 160,
                          name: 'Grie',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 24,
                          state_id: 2,
                          country_id: 160,
                          name: 'Hong',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 25,
                          state_id: 2,
                          country_id: 160,
                          name: 'Jada',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 26,
                          state_id: 2,
                          country_id: 160,
                          name: 'Lamurde',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 27,
                          state_id: 2,
                          country_id: 160,
                          name: 'Madagali',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 28,
                          state_id: 2,
                          country_id: 160,
                          name: 'Maiha',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 29,
                          state_id: 2,
                          country_id: 160,
                          name: 'Mayo Belwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 30,
                          state_id: 2,
                          country_id: 160,
                          name: 'Michika',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 31,
                          state_id: 2,
                          country_id: 160,
                          name: 'Mubi North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 32,
                          state_id: 2,
                          country_id: 160,
                          name: 'Mubi South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 33,
                          state_id: 2,
                          country_id: 160,
                          name: 'Numan',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 34,
                          state_id: 2,
                          country_id: 160,
                          name: 'Shelleng',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 35,
                          state_id: 2,
                          country_id: 160,
                          name: 'Song',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 36,
                          state_id: 2,
                          country_id: 160,
                          name: 'Toungo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 37,
                          state_id: 2,
                          country_id: 160,
                          name: 'Yola North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 38,
                          state_id: 2,
                          country_id: 160,
                          name: 'Yola South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 3,
                  name: 'Akwa Ibom',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 39,
                          state_id: 3,
                          country_id: 160,
                          name: 'Abak',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 40,
                          state_id: 3,
                          country_id: 160,
                          name: 'Eastern Obolo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 41,
                          state_id: 3,
                          country_id: 160,
                          name: 'Eket',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 42,
                          state_id: 3,
                          country_id: 160,
                          name: 'Esit Eket',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 43,
                          state_id: 3,
                          country_id: 160,
                          name: 'Essien Udim',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 44,
                          state_id: 3,
                          country_id: 160,
                          name: 'Etim Ekpo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 45,
                          state_id: 3,
                          country_id: 160,
                          name: 'Etinan',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 46,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ibeno',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 47,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ibesikpo Asutan',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 48,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ibiono-Ibom',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 49,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ika',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 50,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ikono',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 51,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ikot Abasi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 52,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ikot Ekpene',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 53,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ini',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 54,
                          state_id: 3,
                          country_id: 160,
                          name: 'Itu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 55,
                          state_id: 3,
                          country_id: 160,
                          name: 'Mbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 56,
                          state_id: 3,
                          country_id: 160,
                          name: 'Mkpat-Enin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 57,
                          state_id: 3,
                          country_id: 160,
                          name: 'Nsit-Atai',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 58,
                          state_id: 3,
                          country_id: 160,
                          name: 'Nsit-Ibom',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 59,
                          state_id: 3,
                          country_id: 160,
                          name: 'Nsit-Ubium',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 60,
                          state_id: 3,
                          country_id: 160,
                          name: 'Obot Akara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 61,
                          state_id: 3,
                          country_id: 160,
                          name: 'Okobo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 62,
                          state_id: 3,
                          country_id: 160,
                          name: 'Onna',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 63,
                          state_id: 3,
                          country_id: 160,
                          name: 'Oron',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 64,
                          state_id: 3,
                          country_id: 160,
                          name: 'Oruk Anam',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 65,
                          state_id: 3,
                          country_id: 160,
                          name: 'Udung-Uko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 66,
                          state_id: 3,
                          country_id: 160,
                          name: 'Ukanafun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 67,
                          state_id: 3,
                          country_id: 160,
                          name: 'Uruan',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 68,
                          state_id: 3,
                          country_id: 160,
                          name: 'Urue-Offong/Oruko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 69,
                          state_id: 3,
                          country_id: 160,
                          name: 'Uyo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 4,
                  name: 'Anambra',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 70,
                          state_id: 4,
                          country_id: 160,
                          name: 'Aguata',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 71,
                          state_id: 4,
                          country_id: 160,
                          name: 'Anambra East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 72,
                          state_id: 4,
                          country_id: 160,
                          name: 'Anambra West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 73,
                          state_id: 4,
                          country_id: 160,
                          name: 'Anaocha',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 74,
                          state_id: 4,
                          country_id: 160,
                          name: 'Awka North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 75,
                          state_id: 4,
                          country_id: 160,
                          name: 'Awka South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 76,
                          state_id: 4,
                          country_id: 160,
                          name: 'Ayamelum',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 77,
                          state_id: 4,
                          country_id: 160,
                          name: 'Dunukofia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 78,
                          state_id: 4,
                          country_id: 160,
                          name: 'Ekwusigo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 79,
                          state_id: 4,
                          country_id: 160,
                          name: 'Idemili North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 80,
                          state_id: 4,
                          country_id: 160,
                          name: 'Idemili South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 81,
                          state_id: 4,
                          country_id: 160,
                          name: 'Ihiala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 82,
                          state_id: 4,
                          country_id: 160,
                          name: 'Njikoka',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 83,
                          state_id: 4,
                          country_id: 160,
                          name: 'Nnewi North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 84,
                          state_id: 4,
                          country_id: 160,
                          name: 'Nnewi South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 85,
                          state_id: 4,
                          country_id: 160,
                          name: 'Ogbaru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 86,
                          state_id: 4,
                          country_id: 160,
                          name: 'Onitsha North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 87,
                          state_id: 4,
                          country_id: 160,
                          name: 'Onitsha South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 88,
                          state_id: 4,
                          country_id: 160,
                          name: 'Orumba North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 89,
                          state_id: 4,
                          country_id: 160,
                          name: 'Orumba South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 90,
                          state_id: 4,
                          country_id: 160,
                          name: 'Oyi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 5,
                  name: 'Bauchi',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 91,
                          state_id: 5,
                          country_id: 160,
                          name: 'Alkaleri',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 92,
                          state_id: 5,
                          country_id: 160,
                          name: 'Bauchi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 93,
                          state_id: 5,
                          country_id: 160,
                          name: 'Bogoro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 94,
                          state_id: 5,
                          country_id: 160,
                          name: 'Damban',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 95,
                          state_id: 5,
                          country_id: 160,
                          name: 'Darazo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 96,
                          state_id: 5,
                          country_id: 160,
                          name: 'Dass',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 97,
                          state_id: 5,
                          country_id: 160,
                          name: 'Gamawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 98,
                          state_id: 5,
                          country_id: 160,
                          name: 'Ganjuwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 99,
                          state_id: 5,
                          country_id: 160,
                          name: 'Giade',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 100,
                          state_id: 5,
                          country_id: 160,
                          name: 'Itas/Gadau',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 101,
                          state_id: 5,
                          country_id: 160,
                          name: 'Jama\'are',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 102,
                          state_id: 5,
                          country_id: 160,
                          name: 'Katagum',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 103,
                          state_id: 5,
                          country_id: 160,
                          name: 'Kirfi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 104,
                          state_id: 5,
                          country_id: 160,
                          name: 'Misau',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 105,
                          state_id: 5,
                          country_id: 160,
                          name: 'Ningi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 106,
                          state_id: 5,
                          country_id: 160,
                          name: 'Shira',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 107,
                          state_id: 5,
                          country_id: 160,
                          name: 'Tafawa Balewa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 108,
                          state_id: 5,
                          country_id: 160,
                          name: 'Toro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 109,
                          state_id: 5,
                          country_id: 160,
                          name: 'Warji',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 110,
                          state_id: 5,
                          country_id: 160,
                          name: 'Zaki',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 6,
                  name: 'Bayelsa',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 111,
                          state_id: 6,
                          country_id: 160,
                          name: 'Brass',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 112,
                          state_id: 6,
                          country_id: 160,
                          name: 'Ekeremor',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 113,
                          state_id: 6,
                          country_id: 160,
                          name: 'Kolokuma/Opokuma',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 114,
                          state_id: 6,
                          country_id: 160,
                          name: 'Nembe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 115,
                          state_id: 6,
                          country_id: 160,
                          name: 'Ogbia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 116,
                          state_id: 6,
                          country_id: 160,
                          name: 'Sagbama',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 117,
                          state_id: 6,
                          country_id: 160,
                          name: 'Southern Ijaw',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 118,
                          state_id: 6,
                          country_id: 160,
                          name: 'Yenagoa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 7,
                  name: 'Benue',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 119,
                          state_id: 7,
                          country_id: 160,
                          name: 'Ado',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 120,
                          state_id: 7,
                          country_id: 160,
                          name: 'Agatu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 121,
                          state_id: 7,
                          country_id: 160,
                          name: 'Apa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 122,
                          state_id: 7,
                          country_id: 160,
                          name: 'Buruku',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 123,
                          state_id: 7,
                          country_id: 160,
                          name: 'Gboko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 124,
                          state_id: 7,
                          country_id: 160,
                          name: 'Guma',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 125,
                          state_id: 7,
                          country_id: 160,
                          name: 'Gwer East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 126,
                          state_id: 7,
                          country_id: 160,
                          name: 'Gwer West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 127,
                          state_id: 7,
                          country_id: 160,
                          name: 'Katsina-Ala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 128,
                          state_id: 7,
                          country_id: 160,
                          name: 'Konshisha',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 129,
                          state_id: 7,
                          country_id: 160,
                          name: 'Kwande',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 130,
                          state_id: 7,
                          country_id: 160,
                          name: 'Logo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 131,
                          state_id: 7,
                          country_id: 160,
                          name: 'Makurdi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 132,
                          state_id: 7,
                          country_id: 160,
                          name: 'Obi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 133,
                          state_id: 7,
                          country_id: 160,
                          name: 'Ogbadibo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 134,
                          state_id: 7,
                          country_id: 160,
                          name: 'Ohimini',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 135,
                          state_id: 7,
                          country_id: 160,
                          name: 'Oju',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 136,
                          state_id: 7,
                          country_id: 160,
                          name: 'Okpokwu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 137,
                          state_id: 7,
                          country_id: 160,
                          name: 'Oturkpo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 138,
                          state_id: 7,
                          country_id: 160,
                          name: 'Tarka',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 139,
                          state_id: 7,
                          country_id: 160,
                          name: 'Ukum',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 140,
                          state_id: 7,
                          country_id: 160,
                          name: 'Ushongo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 141,
                          state_id: 7,
                          country_id: 160,
                          name: 'Vandeikya',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 8,
                  name: 'Borno',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 142,
                          state_id: 8,
                          country_id: 160,
                          name: 'Abadam',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 143,
                          state_id: 8,
                          country_id: 160,
                          name: 'Askira/Uba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 144,
                          state_id: 8,
                          country_id: 160,
                          name: 'Bama',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 145,
                          state_id: 8,
                          country_id: 160,
                          name: 'Bayo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 146,
                          state_id: 8,
                          country_id: 160,
                          name: 'Biu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 147,
                          state_id: 8,
                          country_id: 160,
                          name: 'Chibok',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 148,
                          state_id: 8,
                          country_id: 160,
                          name: 'Damboa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 149,
                          state_id: 8,
                          country_id: 160,
                          name: 'Dikwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 150,
                          state_id: 8,
                          country_id: 160,
                          name: 'Gubio',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 151,
                          state_id: 8,
                          country_id: 160,
                          name: 'Guzamala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 152,
                          state_id: 8,
                          country_id: 160,
                          name: 'Gwoza',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 153,
                          state_id: 8,
                          country_id: 160,
                          name: 'Hawul',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 154,
                          state_id: 8,
                          country_id: 160,
                          name: 'Jere',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 155,
                          state_id: 8,
                          country_id: 160,
                          name: 'Kaga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 156,
                          state_id: 8,
                          country_id: 160,
                          name: 'Kala/Balge',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 157,
                          state_id: 8,
                          country_id: 160,
                          name: 'Konduga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 158,
                          state_id: 8,
                          country_id: 160,
                          name: 'Kukawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 159,
                          state_id: 8,
                          country_id: 160,
                          name: 'Kwaya Kusar',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 160,
                          state_id: 8,
                          country_id: 160,
                          name: 'Mafa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 161,
                          state_id: 8,
                          country_id: 160,
                          name: 'Magumeri',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 162,
                          state_id: 8,
                          country_id: 160,
                          name: 'Maiduguri',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 163,
                          state_id: 8,
                          country_id: 160,
                          name: 'Marte',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 164,
                          state_id: 8,
                          country_id: 160,
                          name: 'Mobbar',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 165,
                          state_id: 8,
                          country_id: 160,
                          name: 'Monguno',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 166,
                          state_id: 8,
                          country_id: 160,
                          name: 'Ngala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 167,
                          state_id: 8,
                          country_id: 160,
                          name: 'Nganzai',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 168,
                          state_id: 8,
                          country_id: 160,
                          name: 'Shani',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 9,
                  name: 'Cross River',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 169,
                          state_id: 9,
                          country_id: 160,
                          name: 'Abi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 170,
                          state_id: 9,
                          country_id: 160,
                          name: 'Akamkpa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 171,
                          state_id: 9,
                          country_id: 160,
                          name: 'Akpabuyo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 172,
                          state_id: 9,
                          country_id: 160,
                          name: 'Bakassi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 173,
                          state_id: 9,
                          country_id: 160,
                          name: 'Bekwarra',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 174,
                          state_id: 9,
                          country_id: 160,
                          name: 'Biase',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 175,
                          state_id: 9,
                          country_id: 160,
                          name: 'Boki',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 176,
                          state_id: 9,
                          country_id: 160,
                          name: 'Calabar Municipal',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 177,
                          state_id: 9,
                          country_id: 160,
                          name: 'Calabar South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 178,
                          state_id: 9,
                          country_id: 160,
                          name: 'Etung',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 179,
                          state_id: 9,
                          country_id: 160,
                          name: 'Ikom',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 180,
                          state_id: 9,
                          country_id: 160,
                          name: 'Obanliku',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 181,
                          state_id: 9,
                          country_id: 160,
                          name: 'Obubra',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 182,
                          state_id: 9,
                          country_id: 160,
                          name: 'Obudu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 183,
                          state_id: 9,
                          country_id: 160,
                          name: 'Odukpani',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 184,
                          state_id: 9,
                          country_id: 160,
                          name: 'Ogoja',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 185,
                          state_id: 9,
                          country_id: 160,
                          name: 'Yakuur',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 186,
                          state_id: 9,
                          country_id: 160,
                          name: 'Yala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 10,
                  name: 'Delta',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 187,
                          state_id: 10,
                          country_id: 160,
                          name: 'Aniocha North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 188,
                          state_id: 10,
                          country_id: 160,
                          name: 'Aniocha South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 189,
                          state_id: 10,
                          country_id: 160,
                          name: 'Bomadi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 190,
                          state_id: 10,
                          country_id: 160,
                          name: 'Burutu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 191,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ethiope East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 192,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ethiope West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 193,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ika North East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 194,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ika South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 195,
                          state_id: 10,
                          country_id: 160,
                          name: 'Isoko North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 196,
                          state_id: 10,
                          country_id: 160,
                          name: 'Isoko South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 197,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ndokwa East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 198,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ndokwa West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 199,
                          state_id: 10,
                          country_id: 160,
                          name: 'Okpe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 200,
                          state_id: 10,
                          country_id: 160,
                          name: 'Oshimili North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 201,
                          state_id: 10,
                          country_id: 160,
                          name: 'Oshimili South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 202,
                          state_id: 10,
                          country_id: 160,
                          name: 'Patani',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 203,
                          state_id: 10,
                          country_id: 160,
                          name: 'Sapele',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 204,
                          state_id: 10,
                          country_id: 160,
                          name: 'Udu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 205,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ughelli North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 206,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ughelli South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 207,
                          state_id: 10,
                          country_id: 160,
                          name: 'Ukwuani',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 208,
                          state_id: 10,
                          country_id: 160,
                          name: 'Uvwie',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 209,
                          state_id: 10,
                          country_id: 160,
                          name: 'Warri North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 210,
                          state_id: 10,
                          country_id: 160,
                          name: 'Warri South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 211,
                          state_id: 10,
                          country_id: 160,
                          name: 'Warri South West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 11,
                  name: 'Ebonyi',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 212,
                          state_id: 11,
                          country_id: 160,
                          name: 'Abakaliki',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 213,
                          state_id: 11,
                          country_id: 160,
                          name: 'Afikpo North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 214,
                          state_id: 11,
                          country_id: 160,
                          name: 'Afikpo South (Edda)',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 215,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ebonyi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 216,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ezza North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 217,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ezza South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 218,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ikwo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 219,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ishielu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 220,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ivo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 221,
                          state_id: 11,
                          country_id: 160,
                          name: 'Izzi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 222,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ohaozara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 223,
                          state_id: 11,
                          country_id: 160,
                          name: 'Ohaukwu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 224,
                          state_id: 11,
                          country_id: 160,
                          name: 'Onicha',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 12,
                  name: 'Edo',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 225,
                          state_id: 12,
                          country_id: 160,
                          name: 'Akoko-Edo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 226,
                          state_id: 12,
                          country_id: 160,
                          name: 'Egor',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 227,
                          state_id: 12,
                          country_id: 160,
                          name: 'Esan Central',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 228,
                          state_id: 12,
                          country_id: 160,
                          name: 'Esan North-East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 229,
                          state_id: 12,
                          country_id: 160,
                          name: 'Esan South-East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 230,
                          state_id: 12,
                          country_id: 160,
                          name: 'Esan West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 231,
                          state_id: 12,
                          country_id: 160,
                          name: 'Etsako Central',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 232,
                          state_id: 12,
                          country_id: 160,
                          name: 'Etsako East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 233,
                          state_id: 12,
                          country_id: 160,
                          name: 'Etsako West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 234,
                          state_id: 12,
                          country_id: 160,
                          name: 'Igueben',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 235,
                          state_id: 12,
                          country_id: 160,
                          name: 'Ikpoba Okha',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 236,
                          state_id: 12,
                          country_id: 160,
                          name: 'Oredo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 237,
                          state_id: 12,
                          country_id: 160,
                          name: 'Orhionmwon',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 238,
                          state_id: 12,
                          country_id: 160,
                          name: 'Ovia North-East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 239,
                          state_id: 12,
                          country_id: 160,
                          name: 'Ovia South-West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 240,
                          state_id: 12,
                          country_id: 160,
                          name: 'Owan East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 241,
                          state_id: 12,
                          country_id: 160,
                          name: 'Owan West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 242,
                          state_id: 12,
                          country_id: 160,
                          name: 'Uhunmwonde',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 13,
                  name: 'Ekiti',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 243,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ado Ekiti',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 244,
                          state_id: 13,
                          country_id: 160,
                          name: 'Efon',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 245,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ekiti East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 246,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ekiti South-West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 247,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ekiti West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 248,
                          state_id: 13,
                          country_id: 160,
                          name: 'Emure',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 249,
                          state_id: 13,
                          country_id: 160,
                          name: 'Gbonyin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 250,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ido Osi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 251,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ijero',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 252,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ikere',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 253,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ikole',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 254,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ilejemeje',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 255,
                          state_id: 13,
                          country_id: 160,
                          name: 'Irepodun/Ifelodun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 256,
                          state_id: 13,
                          country_id: 160,
                          name: 'Ise/Orun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 257,
                          state_id: 13,
                          country_id: 160,
                          name: 'Moba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 258,
                          state_id: 13,
                          country_id: 160,
                          name: 'Oye',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 14,
                  name: 'Enugu',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 259,
                          state_id: 14,
                          country_id: 160,
                          name: 'Aninri',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 260,
                          state_id: 14,
                          country_id: 160,
                          name: 'Awgu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 261,
                          state_id: 14,
                          country_id: 160,
                          name: 'Enugu East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 262,
                          state_id: 14,
                          country_id: 160,
                          name: 'Enugu North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 263,
                          state_id: 14,
                          country_id: 160,
                          name: 'Enugu South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 264,
                          state_id: 14,
                          country_id: 160,
                          name: 'Ezeagu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 265,
                          state_id: 14,
                          country_id: 160,
                          name: 'Igbo Etiti',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 266,
                          state_id: 14,
                          country_id: 160,
                          name: 'Igbo Eze North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 267,
                          state_id: 14,
                          country_id: 160,
                          name: 'Igbo Eze South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 268,
                          state_id: 14,
                          country_id: 160,
                          name: 'Isi Uzo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 269,
                          state_id: 14,
                          country_id: 160,
                          name: 'Nkanu East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 270,
                          state_id: 14,
                          country_id: 160,
                          name: 'Nkanu West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 271,
                          state_id: 14,
                          country_id: 160,
                          name: 'Nsukka',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 272,
                          state_id: 14,
                          country_id: 160,
                          name: 'Oji River',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 273,
                          state_id: 14,
                          country_id: 160,
                          name: 'Udenu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 274,
                          state_id: 14,
                          country_id: 160,
                          name: 'Udi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 275,
                          state_id: 14,
                          country_id: 160,
                          name: 'Uzo Uwani',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 15,
                  name: 'Gombe',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 282,
                          state_id: 15,
                          country_id: 160,
                          name: 'Akko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 283,
                          state_id: 15,
                          country_id: 160,
                          name: 'Balanga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 284,
                          state_id: 15,
                          country_id: 160,
                          name: 'Billiri',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 285,
                          state_id: 15,
                          country_id: 160,
                          name: 'Dukku',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 286,
                          state_id: 15,
                          country_id: 160,
                          name: 'Funakaye',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 287,
                          state_id: 15,
                          country_id: 160,
                          name: 'Gombe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 288,
                          state_id: 15,
                          country_id: 160,
                          name: 'Kaltungo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 289,
                          state_id: 15,
                          country_id: 160,
                          name: 'Kwami',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 290,
                          state_id: 15,
                          country_id: 160,
                          name: 'Nafada',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 291,
                          state_id: 15,
                          country_id: 160,
                          name: 'Shongom',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 292,
                          state_id: 15,
                          country_id: 160,
                          name: 'Yamaltu/Deba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 16,
                  name: 'Imo',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 293,
                          state_id: 16,
                          country_id: 160,
                          name: 'Aboh Mbaise',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 294,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ahiazu Mbaise',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 295,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ehime Mbano',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 296,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ezinihitte',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 297,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ideato North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 298,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ideato South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 299,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ihitte/Uboma',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 300,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ikeduru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 301,
                          state_id: 16,
                          country_id: 160,
                          name: 'Isiala Mbano',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 302,
                          state_id: 16,
                          country_id: 160,
                          name: 'Isu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 303,
                          state_id: 16,
                          country_id: 160,
                          name: 'Mbaitoli',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 304,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ngor Okpala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 305,
                          state_id: 16,
                          country_id: 160,
                          name: 'Njaba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 306,
                          state_id: 16,
                          country_id: 160,
                          name: 'Nkwerre',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 307,
                          state_id: 16,
                          country_id: 160,
                          name: 'Nwangele',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 308,
                          state_id: 16,
                          country_id: 160,
                          name: 'Obowo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 309,
                          state_id: 16,
                          country_id: 160,
                          name: 'Oguta',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 310,
                          state_id: 16,
                          country_id: 160,
                          name: 'Ohaji/Egbema',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 311,
                          state_id: 16,
                          country_id: 160,
                          name: 'Okigwe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 312,
                          state_id: 16,
                          country_id: 160,
                          name: 'Orlu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 313,
                          state_id: 16,
                          country_id: 160,
                          name: 'Orsu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 314,
                          state_id: 16,
                          country_id: 160,
                          name: 'Oru East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 315,
                          state_id: 16,
                          country_id: 160,
                          name: 'Oru West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 316,
                          state_id: 16,
                          country_id: 160,
                          name: 'Owerri Municipal',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 317,
                          state_id: 16,
                          country_id: 160,
                          name: 'Owerri North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 318,
                          state_id: 16,
                          country_id: 160,
                          name: 'Owerri West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 319,
                          state_id: 16,
                          country_id: 160,
                          name: 'Unuimo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 17,
                  name: 'Jigawa',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 320,
                          state_id: 17,
                          country_id: 160,
                          name: 'Auyo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 321,
                          state_id: 17,
                          country_id: 160,
                          name: 'Babura',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 322,
                          state_id: 17,
                          country_id: 160,
                          name: 'Biriniwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 323,
                          state_id: 17,
                          country_id: 160,
                          name: 'Birnin Kudu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 324,
                          state_id: 17,
                          country_id: 160,
                          name: 'Buji',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 325,
                          state_id: 17,
                          country_id: 160,
                          name: 'Dutse',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 326,
                          state_id: 17,
                          country_id: 160,
                          name: 'Gagarawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 327,
                          state_id: 17,
                          country_id: 160,
                          name: 'Garki',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 328,
                          state_id: 17,
                          country_id: 160,
                          name: 'Gumel',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 329,
                          state_id: 17,
                          country_id: 160,
                          name: 'Guri',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 330,
                          state_id: 17,
                          country_id: 160,
                          name: 'Gwaram',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 331,
                          state_id: 17,
                          country_id: 160,
                          name: 'Gwiwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 332,
                          state_id: 17,
                          country_id: 160,
                          name: 'Hadejia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 333,
                          state_id: 17,
                          country_id: 160,
                          name: 'Jahun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 334,
                          state_id: 17,
                          country_id: 160,
                          name: 'Kafin Hausa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 335,
                          state_id: 17,
                          country_id: 160,
                          name: 'Kaugama',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 336,
                          state_id: 17,
                          country_id: 160,
                          name: 'Kazaure',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 337,
                          state_id: 17,
                          country_id: 160,
                          name: 'Kiri Kasama',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 338,
                          state_id: 17,
                          country_id: 160,
                          name: 'Kiyawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 339,
                          state_id: 17,
                          country_id: 160,
                          name: 'Maigatari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 340,
                          state_id: 17,
                          country_id: 160,
                          name: 'Malam Madori',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 341,
                          state_id: 17,
                          country_id: 160,
                          name: 'Miga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 342,
                          state_id: 17,
                          country_id: 160,
                          name: 'Ringim',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 343,
                          state_id: 17,
                          country_id: 160,
                          name: 'Roni',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 344,
                          state_id: 17,
                          country_id: 160,
                          name: 'Sule Tankarkar',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 345,
                          state_id: 17,
                          country_id: 160,
                          name: 'Taura',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 346,
                          state_id: 17,
                          country_id: 160,
                          name: 'Yankwashi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 18,
                  name: 'Kaduna',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 347,
                          state_id: 18,
                          country_id: 160,
                          name: 'Birnin Gwari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 348,
                          state_id: 18,
                          country_id: 160,
                          name: 'Chikun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 349,
                          state_id: 18,
                          country_id: 160,
                          name: 'Giwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 350,
                          state_id: 18,
                          country_id: 160,
                          name: 'Igabi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 351,
                          state_id: 18,
                          country_id: 160,
                          name: 'Ikara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 352,
                          state_id: 18,
                          country_id: 160,
                          name: 'Jaba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 353,
                          state_id: 18,
                          country_id: 160,
                          name: 'Jema\'a',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 354,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kachia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 355,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kaduna North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 356,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kaduna South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 357,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kagarko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 358,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kajuru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 359,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kaura',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 360,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kauru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 361,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kubau',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 362,
                          state_id: 18,
                          country_id: 160,
                          name: 'Kudan',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 363,
                          state_id: 18,
                          country_id: 160,
                          name: 'Lere',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 364,
                          state_id: 18,
                          country_id: 160,
                          name: 'Makarfi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 365,
                          state_id: 18,
                          country_id: 160,
                          name: 'Sabon Gari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 366,
                          state_id: 18,
                          country_id: 160,
                          name: 'Sanga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 367,
                          state_id: 18,
                          country_id: 160,
                          name: 'Soba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 368,
                          state_id: 18,
                          country_id: 160,
                          name: 'Zangon Kataf',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 369,
                          state_id: 18,
                          country_id: 160,
                          name: 'Zaria',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 19,
                  name: 'Kano',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 370,
                          state_id: 19,
                          country_id: 160,
                          name: 'Ajingi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 371,
                          state_id: 19,
                          country_id: 160,
                          name: 'Albasu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 372,
                          state_id: 19,
                          country_id: 160,
                          name: 'Bagwai',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 373,
                          state_id: 19,
                          country_id: 160,
                          name: 'Bebeji',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 374,
                          state_id: 19,
                          country_id: 160,
                          name: 'Bichi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 375,
                          state_id: 19,
                          country_id: 160,
                          name: 'Bunkure',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 376,
                          state_id: 19,
                          country_id: 160,
                          name: 'Dala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 377,
                          state_id: 19,
                          country_id: 160,
                          name: 'Dambatta',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 378,
                          state_id: 19,
                          country_id: 160,
                          name: 'Dawakin Kudu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 379,
                          state_id: 19,
                          country_id: 160,
                          name: 'Dawakin Tofa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 380,
                          state_id: 19,
                          country_id: 160,
                          name: 'Doguwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 381,
                          state_id: 19,
                          country_id: 160,
                          name: 'Fagge',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 382,
                          state_id: 19,
                          country_id: 160,
                          name: 'Gabasawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 383,
                          state_id: 19,
                          country_id: 160,
                          name: 'Garko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 384,
                          state_id: 19,
                          country_id: 160,
                          name: 'Garun Mallam',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 385,
                          state_id: 19,
                          country_id: 160,
                          name: 'Gaya',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 386,
                          state_id: 19,
                          country_id: 160,
                          name: 'Gezawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 387,
                          state_id: 19,
                          country_id: 160,
                          name: 'Gwale',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 388,
                          state_id: 19,
                          country_id: 160,
                          name: 'Gwarzo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 389,
                          state_id: 19,
                          country_id: 160,
                          name: 'Kabo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 390,
                          state_id: 19,
                          country_id: 160,
                          name: 'Kano Municipal',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 391,
                          state_id: 19,
                          country_id: 160,
                          name: 'Karaye',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 392,
                          state_id: 19,
                          country_id: 160,
                          name: 'Kibiya',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 393,
                          state_id: 19,
                          country_id: 160,
                          name: 'Kiru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 394,
                          state_id: 19,
                          country_id: 160,
                          name: 'Kumbotso',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 395,
                          state_id: 19,
                          country_id: 160,
                          name: 'Kunchi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 396,
                          state_id: 19,
                          country_id: 160,
                          name: 'Kura',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 397,
                          state_id: 19,
                          country_id: 160,
                          name: 'Madobi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 398,
                          state_id: 19,
                          country_id: 160,
                          name: 'Makoda',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 399,
                          state_id: 19,
                          country_id: 160,
                          name: 'Minjibir',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 400,
                          state_id: 19,
                          country_id: 160,
                          name: 'Nasarawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 401,
                          state_id: 19,
                          country_id: 160,
                          name: 'Rano',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 402,
                          state_id: 19,
                          country_id: 160,
                          name: 'Rimin Gado',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 403,
                          state_id: 19,
                          country_id: 160,
                          name: 'Rogo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 404,
                          state_id: 19,
                          country_id: 160,
                          name: 'Shanono',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 405,
                          state_id: 19,
                          country_id: 160,
                          name: 'Sumaila',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 406,
                          state_id: 19,
                          country_id: 160,
                          name: 'Takai',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 407,
                          state_id: 19,
                          country_id: 160,
                          name: 'Tarauni',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 408,
                          state_id: 19,
                          country_id: 160,
                          name: 'Tofa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 409,
                          state_id: 19,
                          country_id: 160,
                          name: 'Tsanyawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 410,
                          state_id: 19,
                          country_id: 160,
                          name: 'Tudun Wada',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 411,
                          state_id: 19,
                          country_id: 160,
                          name: 'Ungogo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 412,
                          state_id: 19,
                          country_id: 160,
                          name: 'Warawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 413,
                          state_id: 19,
                          country_id: 160,
                          name: 'Wudil',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 20,
                  name: 'Katsina',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 414,
                          state_id: 20,
                          country_id: 160,
                          name: 'Bakori',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 415,
                          state_id: 20,
                          country_id: 160,
                          name: 'Batagarawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 416,
                          state_id: 20,
                          country_id: 160,
                          name: 'Batsari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 417,
                          state_id: 20,
                          country_id: 160,
                          name: 'Baure',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 418,
                          state_id: 20,
                          country_id: 160,
                          name: 'Bindawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 419,
                          state_id: 20,
                          country_id: 160,
                          name: 'Charanchi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 420,
                          state_id: 20,
                          country_id: 160,
                          name: 'Dan Musa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 421,
                          state_id: 20,
                          country_id: 160,
                          name: 'Dandume',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 422,
                          state_id: 20,
                          country_id: 160,
                          name: 'Danja',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 423,
                          state_id: 20,
                          country_id: 160,
                          name: 'Daura',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 424,
                          state_id: 20,
                          country_id: 160,
                          name: 'Dutsi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 425,
                          state_id: 20,
                          country_id: 160,
                          name: 'Dutsin Ma',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 426,
                          state_id: 20,
                          country_id: 160,
                          name: 'Faskari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 427,
                          state_id: 20,
                          country_id: 160,
                          name: 'Funtua',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 428,
                          state_id: 20,
                          country_id: 160,
                          name: 'Ingawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 429,
                          state_id: 20,
                          country_id: 160,
                          name: 'Jibia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 430,
                          state_id: 20,
                          country_id: 160,
                          name: 'Kafur',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 431,
                          state_id: 20,
                          country_id: 160,
                          name: 'Kaita',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 432,
                          state_id: 20,
                          country_id: 160,
                          name: 'Kankara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 433,
                          state_id: 20,
                          country_id: 160,
                          name: 'Kankia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 434,
                          state_id: 20,
                          country_id: 160,
                          name: 'Katsina',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 435,
                          state_id: 20,
                          country_id: 160,
                          name: 'Kurfi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 436,
                          state_id: 20,
                          country_id: 160,
                          name: 'Kusada',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 437,
                          state_id: 20,
                          country_id: 160,
                          name: 'Mai\'Adua',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 438,
                          state_id: 20,
                          country_id: 160,
                          name: 'Malumfashi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 439,
                          state_id: 20,
                          country_id: 160,
                          name: 'Mani',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 440,
                          state_id: 20,
                          country_id: 160,
                          name: 'Mashi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 441,
                          state_id: 20,
                          country_id: 160,
                          name: 'Matazu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 442,
                          state_id: 20,
                          country_id: 160,
                          name: 'Musawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 443,
                          state_id: 20,
                          country_id: 160,
                          name: 'Rimi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 444,
                          state_id: 20,
                          country_id: 160,
                          name: 'Sabuwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 445,
                          state_id: 20,
                          country_id: 160,
                          name: 'Safana',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 446,
                          state_id: 20,
                          country_id: 160,
                          name: 'Sandamu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 447,
                          state_id: 20,
                          country_id: 160,
                          name: 'Zango',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 21,
                  name: 'Kebbi',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 448,
                          state_id: 21,
                          country_id: 160,
                          name: 'Aleiro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 449,
                          state_id: 21,
                          country_id: 160,
                          name: 'Arewa Dandi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 450,
                          state_id: 21,
                          country_id: 160,
                          name: 'Argungu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 451,
                          state_id: 21,
                          country_id: 160,
                          name: 'Augie',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 452,
                          state_id: 21,
                          country_id: 160,
                          name: 'Bagudo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 453,
                          state_id: 21,
                          country_id: 160,
                          name: 'Birnin Kebbi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 454,
                          state_id: 21,
                          country_id: 160,
                          name: 'Bunza',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 455,
                          state_id: 21,
                          country_id: 160,
                          name: 'Dandi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 456,
                          state_id: 21,
                          country_id: 160,
                          name: 'Fakai',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 457,
                          state_id: 21,
                          country_id: 160,
                          name: 'Gwandu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 458,
                          state_id: 21,
                          country_id: 160,
                          name: 'Jega',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 459,
                          state_id: 21,
                          country_id: 160,
                          name: 'Kalgo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 460,
                          state_id: 21,
                          country_id: 160,
                          name: 'Koko/Besse',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 461,
                          state_id: 21,
                          country_id: 160,
                          name: 'Maiyama',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 462,
                          state_id: 21,
                          country_id: 160,
                          name: 'Ngaski',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 463,
                          state_id: 21,
                          country_id: 160,
                          name: 'Sakaba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 464,
                          state_id: 21,
                          country_id: 160,
                          name: 'Shanga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 465,
                          state_id: 21,
                          country_id: 160,
                          name: 'Suru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 466,
                          state_id: 21,
                          country_id: 160,
                          name: 'Wasagu/Danko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 467,
                          state_id: 21,
                          country_id: 160,
                          name: 'Yauri',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 468,
                          state_id: 21,
                          country_id: 160,
                          name: 'Zuru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 22,
                  name: 'Kogi',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 469,
                          state_id: 22,
                          country_id: 160,
                          name: 'Adavi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 470,
                          state_id: 22,
                          country_id: 160,
                          name: 'Ajaokuta',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 471,
                          state_id: 22,
                          country_id: 160,
                          name: 'Ankpa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 472,
                          state_id: 22,
                          country_id: 160,
                          name: 'Bassa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 473,
                          state_id: 22,
                          country_id: 160,
                          name: 'Dekina',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 474,
                          state_id: 22,
                          country_id: 160,
                          name: 'Ibaji',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 475,
                          state_id: 22,
                          country_id: 160,
                          name: 'Idah',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 476,
                          state_id: 22,
                          country_id: 160,
                          name: 'Igalamela Odolu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 477,
                          state_id: 22,
                          country_id: 160,
                          name: 'Ijumu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 478,
                          state_id: 22,
                          country_id: 160,
                          name: 'Kabba/Bunu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 479,
                          state_id: 22,
                          country_id: 160,
                          name: 'Kogi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 480,
                          state_id: 22,
                          country_id: 160,
                          name: 'Lokoja',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 481,
                          state_id: 22,
                          country_id: 160,
                          name: 'Mopa Muro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 482,
                          state_id: 22,
                          country_id: 160,
                          name: 'Ofu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 483,
                          state_id: 22,
                          country_id: 160,
                          name: 'Ogori/Magongo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 484,
                          state_id: 22,
                          country_id: 160,
                          name: 'Okehi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 485,
                          state_id: 22,
                          country_id: 160,
                          name: 'Okene',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 486,
                          state_id: 22,
                          country_id: 160,
                          name: 'Olamaboro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 487,
                          state_id: 22,
                          country_id: 160,
                          name: 'Omala',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 488,
                          state_id: 22,
                          country_id: 160,
                          name: 'Yagba East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 489,
                          state_id: 22,
                          country_id: 160,
                          name: 'Yagba West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 23,
                  name: 'Kwara',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 490,
                          state_id: 23,
                          country_id: 160,
                          name: 'Asa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 491,
                          state_id: 23,
                          country_id: 160,
                          name: 'Baruten',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 492,
                          state_id: 23,
                          country_id: 160,
                          name: 'Edu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 493,
                          state_id: 23,
                          country_id: 160,
                          name: 'Ekiti',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 494,
                          state_id: 23,
                          country_id: 160,
                          name: 'Ifelodun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 495,
                          state_id: 23,
                          country_id: 160,
                          name: 'Ilorin East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 496,
                          state_id: 23,
                          country_id: 160,
                          name: 'Ilorin South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 497,
                          state_id: 23,
                          country_id: 160,
                          name: 'Ilorin West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 498,
                          state_id: 23,
                          country_id: 160,
                          name: 'Irepodun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 499,
                          state_id: 23,
                          country_id: 160,
                          name: 'Isin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 500,
                          state_id: 23,
                          country_id: 160,
                          name: 'Kaiama',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 501,
                          state_id: 23,
                          country_id: 160,
                          name: 'Moro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 502,
                          state_id: 23,
                          country_id: 160,
                          name: 'Offa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 503,
                          state_id: 23,
                          country_id: 160,
                          name: 'Oke Ero',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 504,
                          state_id: 23,
                          country_id: 160,
                          name: 'Oyun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 505,
                          state_id: 23,
                          country_id: 160,
                          name: 'Pategi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 24,
                  name: 'Lagos',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 506,
                          state_id: 24,
                          country_id: 160,
                          name: 'Agege',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 507,
                          state_id: 24,
                          country_id: 160,
                          name: 'Ajeromi-Ifelodun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 508,
                          state_id: 24,
                          country_id: 160,
                          name: 'Alimosho',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 509,
                          state_id: 24,
                          country_id: 160,
                          name: 'Amuwo-Odofin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 510,
                          state_id: 24,
                          country_id: 160,
                          name: 'Apapa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 511,
                          state_id: 24,
                          country_id: 160,
                          name: 'Badagry',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 512,
                          state_id: 24,
                          country_id: 160,
                          name: 'Epe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 513,
                          state_id: 24,
                          country_id: 160,
                          name: 'Eti Osa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 514,
                          state_id: 24,
                          country_id: 160,
                          name: 'Ibeju-Lekki',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 515,
                          state_id: 24,
                          country_id: 160,
                          name: 'Ifako-Ijaiye',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 516,
                          state_id: 24,
                          country_id: 160,
                          name: 'Ikeja',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 517,
                          state_id: 24,
                          country_id: 160,
                          name: 'Ikorodu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 518,
                          state_id: 24,
                          country_id: 160,
                          name: 'Kosofe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 519,
                          state_id: 24,
                          country_id: 160,
                          name: 'Lagos Island',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 520,
                          state_id: 24,
                          country_id: 160,
                          name: 'Lagos Mainland',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 521,
                          state_id: 24,
                          country_id: 160,
                          name: 'Mushin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 522,
                          state_id: 24,
                          country_id: 160,
                          name: 'Ojo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 523,
                          state_id: 24,
                          country_id: 160,
                          name: 'Oshodi-Isolo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 524,
                          state_id: 24,
                          country_id: 160,
                          name: 'Shomolu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 525,
                          state_id: 24,
                          country_id: 160,
                          name: 'Surulere',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 25,
                  name: 'Nasarawa',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 526,
                          state_id: 25,
                          country_id: 160,
                          name: 'Akwanga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 527,
                          state_id: 25,
                          country_id: 160,
                          name: 'Awe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 528,
                          state_id: 25,
                          country_id: 160,
                          name: 'Doma',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 529,
                          state_id: 25,
                          country_id: 160,
                          name: 'Karu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 530,
                          state_id: 25,
                          country_id: 160,
                          name: 'Keana',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 531,
                          state_id: 25,
                          country_id: 160,
                          name: 'Keffi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 532,
                          state_id: 25,
                          country_id: 160,
                          name: 'Kokona',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 533,
                          state_id: 25,
                          country_id: 160,
                          name: 'Lafia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 534,
                          state_id: 25,
                          country_id: 160,
                          name: 'Nasarawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 535,
                          state_id: 25,
                          country_id: 160,
                          name: 'Nasarawa Egon',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 536,
                          state_id: 25,
                          country_id: 160,
                          name: 'Obi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 537,
                          state_id: 25,
                          country_id: 160,
                          name: 'Toto',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 538,
                          state_id: 25,
                          country_id: 160,
                          name: 'Wamba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 26,
                  name: 'Niger',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 539,
                          state_id: 26,
                          country_id: 160,
                          name: 'Agaie',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 540,
                          state_id: 26,
                          country_id: 160,
                          name: 'Agwara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 541,
                          state_id: 26,
                          country_id: 160,
                          name: 'Bida',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 542,
                          state_id: 26,
                          country_id: 160,
                          name: 'Borgu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 543,
                          state_id: 26,
                          country_id: 160,
                          name: 'Bosso',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 544,
                          state_id: 26,
                          country_id: 160,
                          name: 'Chanchaga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 545,
                          state_id: 26,
                          country_id: 160,
                          name: 'Edati',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 546,
                          state_id: 26,
                          country_id: 160,
                          name: 'Gbako',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 547,
                          state_id: 26,
                          country_id: 160,
                          name: 'Gurara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 548,
                          state_id: 26,
                          country_id: 160,
                          name: 'Katcha',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 549,
                          state_id: 26,
                          country_id: 160,
                          name: 'Kontagora',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 550,
                          state_id: 26,
                          country_id: 160,
                          name: 'Lapai',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 551,
                          state_id: 26,
                          country_id: 160,
                          name: 'Lavun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 552,
                          state_id: 26,
                          country_id: 160,
                          name: 'Magama',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 553,
                          state_id: 26,
                          country_id: 160,
                          name: 'Mariga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 554,
                          state_id: 26,
                          country_id: 160,
                          name: 'Mashegu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 555,
                          state_id: 26,
                          country_id: 160,
                          name: 'Mokwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 556,
                          state_id: 26,
                          country_id: 160,
                          name: 'Moya',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 557,
                          state_id: 26,
                          country_id: 160,
                          name: 'Paikoro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 558,
                          state_id: 26,
                          country_id: 160,
                          name: 'Rafi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 559,
                          state_id: 26,
                          country_id: 160,
                          name: 'Rijau',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 560,
                          state_id: 26,
                          country_id: 160,
                          name: 'Shiroro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 561,
                          state_id: 26,
                          country_id: 160,
                          name: 'Suleja',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 562,
                          state_id: 26,
                          country_id: 160,
                          name: 'Tafa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 563,
                          state_id: 26,
                          country_id: 160,
                          name: 'Wushishi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 27,
                  name: 'Ogun',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 564,
                          state_id: 27,
                          country_id: 160,
                          name: 'Abeokuta North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 565,
                          state_id: 27,
                          country_id: 160,
                          name: 'Abeokuta South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 566,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ado-Odo/Ota',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 567,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ewekoro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 568,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ifo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 569,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ijebu East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 570,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ijebu North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 571,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ijebu North East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 572,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ijebu Ode',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 573,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ikenne',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 574,
                          state_id: 27,
                          country_id: 160,
                          name: 'Imeko Afon',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 575,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ipokia',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 576,
                          state_id: 27,
                          country_id: 160,
                          name: 'Obafemi Owode',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 577,
                          state_id: 27,
                          country_id: 160,
                          name: 'Odeda',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 578,
                          state_id: 27,
                          country_id: 160,
                          name: 'Odogbolu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 579,
                          state_id: 27,
                          country_id: 160,
                          name: 'Ogun Waterside',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 580,
                          state_id: 27,
                          country_id: 160,
                          name: 'Remo North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 581,
                          state_id: 27,
                          country_id: 160,
                          name: 'Shagamu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 582,
                          state_id: 27,
                          country_id: 160,
                          name: 'Yewa North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 583,
                          state_id: 27,
                          country_id: 160,
                          name: 'Yewa South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 28,
                  name: 'Ondo',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 584,
                          state_id: 28,
                          country_id: 160,
                          name: 'Akoko North-East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 585,
                          state_id: 28,
                          country_id: 160,
                          name: 'Akoko North-West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 586,
                          state_id: 28,
                          country_id: 160,
                          name: 'Akoko South-East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 587,
                          state_id: 28,
                          country_id: 160,
                          name: 'Akoko South-West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 588,
                          state_id: 28,
                          country_id: 160,
                          name: 'Akure North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 589,
                          state_id: 28,
                          country_id: 160,
                          name: 'Akure South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 590,
                          state_id: 28,
                          country_id: 160,
                          name: 'Ese Odo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 591,
                          state_id: 28,
                          country_id: 160,
                          name: 'Idanre',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 592,
                          state_id: 28,
                          country_id: 160,
                          name: 'Ifedore',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 593,
                          state_id: 28,
                          country_id: 160,
                          name: 'Ilaje',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 594,
                          state_id: 28,
                          country_id: 160,
                          name: 'Ile Oluji/Okeigbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 595,
                          state_id: 28,
                          country_id: 160,
                          name: 'Irele',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 596,
                          state_id: 28,
                          country_id: 160,
                          name: 'Odigbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 597,
                          state_id: 28,
                          country_id: 160,
                          name: 'Okitipupa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 598,
                          state_id: 28,
                          country_id: 160,
                          name: 'Ondo East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 599,
                          state_id: 28,
                          country_id: 160,
                          name: 'Ondo West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 600,
                          state_id: 28,
                          country_id: 160,
                          name: 'Ose',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 601,
                          state_id: 28,
                          country_id: 160,
                          name: 'Owo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 29,
                  name: 'Osun',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 602,
                          state_id: 29,
                          country_id: 160,
                          name: 'Aiyedaade',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 603,
                          state_id: 29,
                          country_id: 160,
                          name: 'Aiyedire',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 604,
                          state_id: 29,
                          country_id: 160,
                          name: 'Atakunmosa East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 605,
                          state_id: 29,
                          country_id: 160,
                          name: 'Atakunmosa West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 606,
                          state_id: 29,
                          country_id: 160,
                          name: 'Boluwaduro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 607,
                          state_id: 29,
                          country_id: 160,
                          name: 'Boripe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 608,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ede North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 609,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ede South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 610,
                          state_id: 29,
                          country_id: 160,
                          name: 'Egbedore',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 611,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ejigbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 612,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ife Central',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 613,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ife East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 614,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ife North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 615,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ife South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 616,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ifedayo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 617,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ifelodun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 618,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ila',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 619,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ilesa East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 620,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ilesa West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 621,
                          state_id: 29,
                          country_id: 160,
                          name: 'Irepodun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 622,
                          state_id: 29,
                          country_id: 160,
                          name: 'Irewole',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 623,
                          state_id: 29,
                          country_id: 160,
                          name: 'Isokan',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 624,
                          state_id: 29,
                          country_id: 160,
                          name: 'Iwo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 625,
                          state_id: 29,
                          country_id: 160,
                          name: 'Obokun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 626,
                          state_id: 29,
                          country_id: 160,
                          name: 'Odo Otin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 627,
                          state_id: 29,
                          country_id: 160,
                          name: 'Ola Oluwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 628,
                          state_id: 29,
                          country_id: 160,
                          name: 'Olorunda',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 629,
                          state_id: 29,
                          country_id: 160,
                          name: 'Oriade',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 630,
                          state_id: 29,
                          country_id: 160,
                          name: 'Orolu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 631,
                          state_id: 29,
                          country_id: 160,
                          name: 'Osogbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 30,
                  name: 'Oyo',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 632,
                          state_id: 30,
                          country_id: 160,
                          name: 'Afijio',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 633,
                          state_id: 30,
                          country_id: 160,
                          name: 'Akinyele',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 634,
                          state_id: 30,
                          country_id: 160,
                          name: 'Atiba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 635,
                          state_id: 30,
                          country_id: 160,
                          name: 'Atisbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 636,
                          state_id: 30,
                          country_id: 160,
                          name: 'Egbeda',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 637,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibadan North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 638,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibadan North-East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 639,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibadan North-West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 640,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibadan South-East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 641,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibadan South-West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 642,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibarapa Central',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 643,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibarapa East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 644,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ibarapa North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 645,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ido',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 646,
                          state_id: 30,
                          country_id: 160,
                          name: 'Irepo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 647,
                          state_id: 30,
                          country_id: 160,
                          name: 'Iseyin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 648,
                          state_id: 30,
                          country_id: 160,
                          name: 'Itesiwaju',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 649,
                          state_id: 30,
                          country_id: 160,
                          name: 'Iwajowa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 650,
                          state_id: 30,
                          country_id: 160,
                          name: 'Kajola',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 651,
                          state_id: 30,
                          country_id: 160,
                          name: 'Lagelu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 652,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ogbomosho North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 653,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ogbomosho South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 654,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ogo Oluwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 655,
                          state_id: 30,
                          country_id: 160,
                          name: 'Olorunsogo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 656,
                          state_id: 30,
                          country_id: 160,
                          name: 'Oluyole',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 657,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ona Ara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 658,
                          state_id: 30,
                          country_id: 160,
                          name: 'Orelope',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 659,
                          state_id: 30,
                          country_id: 160,
                          name: 'Ori Ire',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 660,
                          state_id: 30,
                          country_id: 160,
                          name: 'Oyo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 661,
                          state_id: 30,
                          country_id: 160,
                          name: 'Oyo East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 662,
                          state_id: 30,
                          country_id: 160,
                          name: 'Saki East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 663,
                          state_id: 30,
                          country_id: 160,
                          name: 'Saki West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 664,
                          state_id: 30,
                          country_id: 160,
                          name: 'Surulere',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 31,
                  name: 'Plateau',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 665,
                          state_id: 31,
                          country_id: 160,
                          name: 'Barkin Ladi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 666,
                          state_id: 31,
                          country_id: 160,
                          name: 'Bassa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 667,
                          state_id: 31,
                          country_id: 160,
                          name: 'Bokkos',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 668,
                          state_id: 31,
                          country_id: 160,
                          name: 'Jos East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 669,
                          state_id: 31,
                          country_id: 160,
                          name: 'Jos North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 670,
                          state_id: 31,
                          country_id: 160,
                          name: 'Jos South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 671,
                          state_id: 31,
                          country_id: 160,
                          name: 'Kanam',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 672,
                          state_id: 31,
                          country_id: 160,
                          name: 'Kanke',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 673,
                          state_id: 31,
                          country_id: 160,
                          name: 'Langtang North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 674,
                          state_id: 31,
                          country_id: 160,
                          name: 'Langtang South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 675,
                          state_id: 31,
                          country_id: 160,
                          name: 'Mangu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 676,
                          state_id: 31,
                          country_id: 160,
                          name: 'Mikang',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 677,
                          state_id: 31,
                          country_id: 160,
                          name: 'Pankshin',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 678,
                          state_id: 31,
                          country_id: 160,
                          name: 'Qua\'an Pan',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 679,
                          state_id: 31,
                          country_id: 160,
                          name: 'Riyom',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 680,
                          state_id: 31,
                          country_id: 160,
                          name: 'Shendam',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 681,
                          state_id: 31,
                          country_id: 160,
                          name: 'Wase',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 32,
                  name: 'Rivers',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 682,
                          state_id: 32,
                          country_id: 160,
                          name: 'Abua/Odual',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 683,
                          state_id: 32,
                          country_id: 160,
                          name: 'Ahoada East',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 684,
                          state_id: 32,
                          country_id: 160,
                          name: 'Ahoada West',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 685,
                          state_id: 32,
                          country_id: 160,
                          name: 'Akuku-Toru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 686,
                          state_id: 32,
                          country_id: 160,
                          name: 'Andoni',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 687,
                          state_id: 32,
                          country_id: 160,
                          name: 'Asari-Toru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 688,
                          state_id: 32,
                          country_id: 160,
                          name: 'Bonny',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 689,
                          state_id: 32,
                          country_id: 160,
                          name: 'Degema',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 690,
                          state_id: 32,
                          country_id: 160,
                          name: 'Eleme',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 691,
                          state_id: 32,
                          country_id: 160,
                          name: 'Emuoha',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 692,
                          state_id: 32,
                          country_id: 160,
                          name: 'Etche',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 693,
                          state_id: 32,
                          country_id: 160,
                          name: 'Gokana',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 694,
                          state_id: 32,
                          country_id: 160,
                          name: 'Ikwerre',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 695,
                          state_id: 32,
                          country_id: 160,
                          name: 'Khana',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 696,
                          state_id: 32,
                          country_id: 160,
                          name: 'Obio/Akpor',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 697,
                          state_id: 32,
                          country_id: 160,
                          name: 'Ogba/Egbema/Ndoni',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 698,
                          state_id: 32,
                          country_id: 160,
                          name: 'Ogu/Bolo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 699,
                          state_id: 32,
                          country_id: 160,
                          name: 'Okrika',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 700,
                          state_id: 32,
                          country_id: 160,
                          name: 'Omuma',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 701,
                          state_id: 32,
                          country_id: 160,
                          name: 'Opobo/Nkoro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 702,
                          state_id: 32,
                          country_id: 160,
                          name: 'Oyigbo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 703,
                          state_id: 32,
                          country_id: 160,
                          name: 'Port Harcourt',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 704,
                          state_id: 32,
                          country_id: 160,
                          name: 'Tai',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 33,
                  name: 'Sokoto',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 705,
                          state_id: 33,
                          country_id: 160,
                          name: 'Binji',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 706,
                          state_id: 33,
                          country_id: 160,
                          name: 'Bodinga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 707,
                          state_id: 33,
                          country_id: 160,
                          name: 'Dange Shuni',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 708,
                          state_id: 33,
                          country_id: 160,
                          name: 'Gada',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 709,
                          state_id: 33,
                          country_id: 160,
                          name: 'Goronyo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 710,
                          state_id: 33,
                          country_id: 160,
                          name: 'Gudu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 711,
                          state_id: 33,
                          country_id: 160,
                          name: 'Gwadabawa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 712,
                          state_id: 33,
                          country_id: 160,
                          name: 'Illela',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 713,
                          state_id: 33,
                          country_id: 160,
                          name: 'Isa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 714,
                          state_id: 33,
                          country_id: 160,
                          name: 'Kebbe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 715,
                          state_id: 33,
                          country_id: 160,
                          name: 'Kware',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 716,
                          state_id: 33,
                          country_id: 160,
                          name: 'Rabah',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 717,
                          state_id: 33,
                          country_id: 160,
                          name: 'Sabon Birni',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 718,
                          state_id: 33,
                          country_id: 160,
                          name: 'Shagari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 719,
                          state_id: 33,
                          country_id: 160,
                          name: 'Silame',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 720,
                          state_id: 33,
                          country_id: 160,
                          name: 'Sokoto North',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 721,
                          state_id: 33,
                          country_id: 160,
                          name: 'Sokoto South',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 722,
                          state_id: 33,
                          country_id: 160,
                          name: 'Tambuwal',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 723,
                          state_id: 33,
                          country_id: 160,
                          name: 'Tangaza',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 724,
                          state_id: 33,
                          country_id: 160,
                          name: 'Tureta',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 725,
                          state_id: 33,
                          country_id: 160,
                          name: 'Wamako',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 726,
                          state_id: 33,
                          country_id: 160,
                          name: 'Wurno',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 727,
                          state_id: 33,
                          country_id: 160,
                          name: 'Yabo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 34,
                  name: 'Taraba',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 728,
                          state_id: 34,
                          country_id: 160,
                          name: 'Ardo Kola',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 729,
                          state_id: 34,
                          country_id: 160,
                          name: 'Bali',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 730,
                          state_id: 34,
                          country_id: 160,
                          name: 'Donga',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 731,
                          state_id: 34,
                          country_id: 160,
                          name: 'Gashaka',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 732,
                          state_id: 34,
                          country_id: 160,
                          name: 'Gassol',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 733,
                          state_id: 34,
                          country_id: 160,
                          name: 'Ibi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 734,
                          state_id: 34,
                          country_id: 160,
                          name: 'Jalingo',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 735,
                          state_id: 34,
                          country_id: 160,
                          name: 'Karim Lamido',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 736,
                          state_id: 34,
                          country_id: 160,
                          name: 'Kumi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 737,
                          state_id: 34,
                          country_id: 160,
                          name: 'Lau',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 738,
                          state_id: 34,
                          country_id: 160,
                          name: 'Sardauna',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 739,
                          state_id: 34,
                          country_id: 160,
                          name: 'Takum',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 740,
                          state_id: 34,
                          country_id: 160,
                          name: 'Ussa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 741,
                          state_id: 34,
                          country_id: 160,
                          name: 'Wukari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 742,
                          state_id: 34,
                          country_id: 160,
                          name: 'Yorro',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 743,
                          state_id: 34,
                          country_id: 160,
                          name: 'Zing',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 35,
                  name: 'Yobe',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 744,
                          state_id: 35,
                          country_id: 160,
                          name: 'Bade',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 745,
                          state_id: 35,
                          country_id: 160,
                          name: 'Bursari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 746,
                          state_id: 35,
                          country_id: 160,
                          name: 'Damaturu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 747,
                          state_id: 35,
                          country_id: 160,
                          name: 'Fika',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 748,
                          state_id: 35,
                          country_id: 160,
                          name: 'Fune',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 749,
                          state_id: 35,
                          country_id: 160,
                          name: 'Geidam',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 750,
                          state_id: 35,
                          country_id: 160,
                          name: 'Gujba',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 751,
                          state_id: 35,
                          country_id: 160,
                          name: 'Gulani',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 752,
                          state_id: 35,
                          country_id: 160,
                          name: 'Jakusko',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 753,
                          state_id: 35,
                          country_id: 160,
                          name: 'Karasuwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 754,
                          state_id: 35,
                          country_id: 160,
                          name: 'Machina',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 755,
                          state_id: 35,
                          country_id: 160,
                          name: 'Nangere',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 756,
                          state_id: 35,
                          country_id: 160,
                          name: 'Nguru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 757,
                          state_id: 35,
                          country_id: 160,
                          name: 'Potiskum',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 758,
                          state_id: 35,
                          country_id: 160,
                          name: 'Tarmuwa',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 759,
                          state_id: 35,
                          country_id: 160,
                          name: 'Yunusari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 760,
                          state_id: 35,
                          country_id: 160,
                          name: 'Yusufari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 36,
                  name: 'Zamfara',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 761,
                          state_id: 36,
                          country_id: 160,
                          name: 'Anka',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 762,
                          state_id: 36,
                          country_id: 160,
                          name: 'Bakura',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 763,
                          state_id: 36,
                          country_id: 160,
                          name: 'Birnin Magaji/Kiyaw',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 764,
                          state_id: 36,
                          country_id: 160,
                          name: 'Bukkuyum',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 765,
                          state_id: 36,
                          country_id: 160,
                          name: 'Bungudu',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 766,
                          state_id: 36,
                          country_id: 160,
                          name: 'Chafe',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 767,
                          state_id: 36,
                          country_id: 160,
                          name: 'Gummi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 768,
                          state_id: 36,
                          country_id: 160,
                          name: 'Gusau',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 769,
                          state_id: 36,
                          country_id: 160,
                          name: 'Kaura Namoda',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 770,
                          state_id: 36,
                          country_id: 160,
                          name: 'Maradun',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 771,
                          state_id: 36,
                          country_id: 160,
                          name: 'Maru',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 772,
                          state_id: 36,
                          country_id: 160,
                          name: 'Shinkafi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 773,
                          state_id: 36,
                          country_id: 160,
                          name: 'Talata Mafara',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 774,
                          state_id: 36,
                          country_id: 160,
                          name: 'Zurmi',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
              {
                  id: 37,
                  name: 'FCT',
                  country_id: 160,
                  created_at: '2017-12-02T14:56:00.000000Z',
                  updated_at: '2017-12-02T14:56:00.000000Z',
                  lgas: [
                      {
                          id: 276,
                          state_id: 37,
                          country_id: 160,
                          name: 'Abaji',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 277,
                          state_id: 37,
                          country_id: 160,
                          name: 'Bwari',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 278,
                          state_id: 37,
                          country_id: 160,
                          name: 'Gwagwalada',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 279,
                          state_id: 37,
                          country_id: 160,
                          name: 'Kuje',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 280,
                          state_id: 37,
                          country_id: 160,
                          name: 'Kwali',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                      {
                          id: 281,
                          state_id: 37,
                          country_id: 160,
                          name: 'Municipal Area Council',
                          created_at: '2017-12-02T14:58:59.000000Z',
                          updated_at: '2017-12-02T14:58:59.000000Z',
                      },
                  ],
              },
          ],
      },
    },
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_NECESSARY_PARAMS(state, params) {
      state.params = params
    },
  },
  actions: {
    fetchNecessaryParams({ commit }) {
      const fetchParamsResource = new Resource('fetch-necessary-params')
      fetchParamsResource.list()
        .then(response => {
          commit('SET_NECESSARY_PARAMS', response)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
